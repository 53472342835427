import React from "react";
import { withTranslation, useTranslation } from 'react-i18next';
import { langs, toggleDropDown } from '../components/Helpers';
import Flags from 'country-flag-icons/react/3x2'
import '../assets/css/components/dropdown.css';

//-> Sélecteur de langue
const LanguageSwitcher = (props) => {

  const { i18n } = useTranslation();
  const { className } = props;

  let getFlagCode = (lang) => {
    if(langs[lang] === undefined) {
      return "FR";
    } else {
      return langs[lang].flag;
    }
  }
  
  const changeLanguage = (e) => {
    let lng = e.target.value;
    i18n.changeLanguage(lng);
  }
  
  let activeLang = i18n.language.substring(0, 2);
  let activeFlag = getFlagCode(activeLang);
  let currLangName = langs[activeLang] !== undefined ? langs[activeLang].name : "français";
  let Flag = Flags[activeFlag];

  

  return(
    <ul className={`dropdown-container${(className !== undefined && className !== "undefined") ? " " + className : ""} languages box-shadow`}>
      <li className="current" onClick={(e) => {
        let newString = "";

        if (className !== undefined && className !== "undefined") {
          let classNames = className.split(" ");
          if (classNames.length > 0) {
            for (var i=0;i<classNames.length;i++) {
              newString += "." + classNames[i]
            }
          }
        }
       
        toggleDropDown(newString + ".languages")
      }}>
      <Flag/>{currLangName}
        <ul className="dropdown">
        {Object.keys(langs).map((key, i) => {

          let LoopedFlag = Flags[langs[key].flag]
          let lang = langs[key].name
          if (lang !== currLangName) {
            
            return (
                <li key={i}>
                  <button onClick={changeLanguage} value={key}>
                    <LoopedFlag title={langs[key].name} />
                    {langs[key].name}
                  </button>
                </li>
            )
              
          } else {
            return false
          }
         
        })}
        </ul>
      </li>
     
    </ul>
  )
}

export default withTranslation()(LanguageSwitcher)