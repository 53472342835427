//-> Page de paiement

import { useState, useEffect } from "react";
import { isExpired } from "react-jwt";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const Payment = (props) => {
    const { t } = useTranslation()
    const [amount, setAmount] = useState("");
    const [paymentInitiated, setPaymentInitiated] = useState(false);
    const [paymentAvailable, setPaymentAvailable] = useState(null);
    const [panelTransition, setPanelTransition] = useState("fadeOut");
    const { cards, loaderState, hideAccounts, history } = props;

    useEffect(() => {

        const sendToLogmis = async() => {
            const token = JSON.parse(localStorage.getItem("token"));
            
            if (!isExpired(token.data)) {

                const paylinetoken = localStorage.getItem("paylinetoken");

                if (paylinetoken !== null) {

                    const { REACT_APP_API_PAYMENT_URL, REACT_APP_API_KEY } = process.env;
        
                    await fetch(REACT_APP_API_PAYMENT_URL + "/tempaccept/" + paylinetoken, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Accept' : '*/*',
                            'X-API-KEY': REACT_APP_API_KEY,
                            "Authorization" : "Bearer " + token.data
                        }
    
                    })
                }
         
            } else {
                // Error
            }
        }


        if (cards !== null) {
            if (Number(cards.blocageetat) === 1 || Number(cards.avecrechargement) === 0) {
                history.push({
                    pathname:"/dashboard"
                })
            }
        }

        if (loaderState === "off hidden" && (hideAccounts === "hide hidden" || document.body.classList.contains("desktop"))) {
            setTimeout(function() {
                setPanelTransition("fadeIn")
            }, 150)
        }
    
        if (localStorage.getItem("paylinestate") === "PAYMENT_SUCCESS") {
            sendToLogmis()
        }
        
        return () => {
            setPanelTransition("fadeOut")
        }

        
    }, [loaderState, hideAccounts, cards, history]);


    

    const payment = async (id, montant) => {
        const token = JSON.parse(localStorage.getItem("token"));
        
        if (!isExpired(token.data)) {
            
            if (!paymentInitiated) {
                localStorage.removeItem("paylinetoken")
                localStorage.removeItem("paylinestate")
                setPaymentInitiated(true)
                const { REACT_APP_API_PAYMENT_URL, REACT_APP_API_KEY } = process.env;
        
                await fetch(REACT_APP_API_PAYMENT_URL + "/ini/" + id, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    },
                    body : JSON.stringify({ 
                        "montant" : montant ,
                        "returnurl": document.location.href,
                        "cancelurl": document.location.href
                    })

                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {
                        if (result.reponse === "Created") {
                            
                            if (window.Payline.Api !== undefined) {
                                let texte= JSON.stringify(result);
                                let t1 = JSON.parse(texte);
                                //let t2 = t1.data.redirectURL;
                                let t3 = t1.data.token;
                                localStorage.setItem("paylinetoken", t3);
                                window.Payline.Api.init();  
                                window.Payline.Api.reset(t3,null);
                                window.Payline.Api.show();
                                setPaymentInitiated(false)
                                setPaymentAvailable(true)

                            } else {
                                setPaymentInitiated(false)
                                setPaymentAvailable(false)
                            }
                            
                        } else {
                            setPaymentInitiated(false)
                            setPaymentAvailable(false)
                        }
                        
                    },
                    (error) => {
                        return error
                    }
                )
            }
                
        } else {
            // Error
        }
    }

    
    const handleSubmit = (e) => {
        e.preventDefault()
        if (amount !== "") {

            let newAmount = amount

            if (newAmount.indexOf(",") !== -1) {
                newAmount = newAmount.replace(",", ".")
                newAmount = parseFloat(newAmount).toFixed(2);
            }

            payment(cards.id, newAmount)
        } else {
            alert(t("Payment:enter-amount"))
        }
    }

    const onEnterPress = (e) => {
      if (e.key === "Enter") {
        handleSubmit(e)
      }
    }
    

    return (
        <>
            {cards !== null &&
                <div className={`payment-container panel-container flex flex-direction-column justify-content-between align-items-stretch relative ${panelTransition}${props.editModalIsOpen ? " modal-open" : ""}`}>
                    
                    <div className="panel-title payment desktop-all bolder-x relative">{parse(t("Payment:reload-account"))}</div>

                        <div className="payment-wrapper">

                            <form onSubmit={handleSubmit}>
                                <div className="block bg-grey-light">
                                    <div className="block-header bolder relative">{t("Payment:bank-reload")}</div>

                                    <div className="form-group">
                                        <label htmlFor="reload">{t("Payment:amount-input")}
                                            <input type="text" maxLength={20} name="reload" id="reload" className="form-control" onChange={e => {
                                                setAmount(e.target.value)
                                            }} onKeyDown={onEnterPress} /> 
                                        </label>
                                    </div>
                                
                                </div>

                                {paymentAvailable !== null && !paymentAvailable &&
                                <div className='form-errors'>
                                    <p className="bg-error2 text-center">
                                    <strong>{parse(t("FormErrors:error").toUpperCase())}</strong>&nbsp;
                                    {parse(t("Payment:payment-unavailable"))}
                                    </p>
                                </div>
                                }

                                {localStorage.getItem("paylinestate") === "PAYMENT_SUCCESS" &&
                                    <div className='form-success'>
                                        <p className="bg-success2 text-center"><span className='valid'></span>{parse(t("Payment:payment-success"))}</p>
                                    </div>
                                }
                                
                                {localStorage.getItem("paylinestate") !== null && localStorage.getItem("paylinestate") !== "PAYMENT_SUCCESS" &&
                                    <div className='form-errors'>
                                        <p className="bg-error2 text-center">
                                        <strong>{parse(t("FormErrors:error").toUpperCase())}</strong>&nbsp;
                                        {parse(t("Payment:payment-failure"))}
                                        </p>
                                    </div>
                                }

                                <div className="text-center block full-width"><button className="continue" type="submit">{t("Payment:reload")}</button></div>
                            </form>
                        </div>
                        

                    <div style={{height:"1px"}}></div>

                </div>
                
            }
        </>
    )
    
}

export default Payment