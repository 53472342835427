//-> Gestion des panneaux du tableau de bord

import React, { useEffect, useState, useRef } from "react";
import parse from 'html-react-parser';
import { getAccountsByStatus, toggleDropDown, askNewPassword, clearLocalStorage } from "../../components/Helpers";
import familypicDesktop from '../../assets/images/family-desktop.png';
import editCardMob from '../../assets/images/edit-card-mobile.png';
import editCardDesk from '../../assets/images/edit-card-desktop.png';
import blockCardMob from '../../assets/images/block-card-mobile.png';
import blockCardDesk from '../../assets/images/block-card-desktop.png';
import bgSolde from '../../assets/images/background-solde.png';
import bgPayment from '../../assets/images/background-payment.png';
import bgRefund from '../../assets/images/background-refund.png';
import bgContact from '../../assets/images/background-contact.png';
import loader from '../../assets/images/loader.svg';
import { useRouteMatch, Switch, Route, useParams, NavLink } from "react-router-dom";
import LanguageSwitcher from '../../components/LanguageSwitcher';
import { useTranslation } from "react-i18next"
import { isExpired } from "react-jwt";
import Modal from 'react-modal';
import Menus from './Menus';
import EditModal from './EditModal';
import BlockModal from './BlockModal';
import AccountShortInfoMobile from "./AccountShortInfoMobile";
import AccountShortInfoDesktop from "./AccountShortInfoDesktop";
import DisplayAccounts from "./DisplayAccounts";
import AccountBalance from "./AccountBalance";
import CardLists from "./CardLists";
import RFID from "./RFID";
import Operations from "./Operations";
import TheftLossModule from "./TheftLossModule";
import Payment from "./Payment";
import Refund from "./Refund";
import Contact from "./Contact";
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import '../../assets/css/elements/Dashboard/Menu.css';
import '../../assets/css/elements/Dashboard/Accounts.css';
import '../../assets/css/elements/Dashboard/Theft.css';
import '../../assets/css/elements/Dashboard/Payment.css';
import '../../assets/css/elements/Dashboard/Refund.css';
import '../../assets/css/elements/Dashboard/Contact.css';
import '../../../node_modules/swiper/swiper.scss';
import '../../../node_modules/swiper/modules/navigation/navigation.scss';

Modal.setAppElement('#root');

const Accounts = (props) => {
    const { i18n, t } = useTranslation()
    const { url } = useRouteMatch()
    const { id } = useParams()
    const [data, setData] = useState(null);
    const [cards, setCards] = useState(null);
    const [hideAccounts, setHideAccounts] = useState("");
    const [toggleActiveAccount, setToggleActiveAccount] = useState(true);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [blockModalIsOpen, setBlockModalIsOpen] = useState(false);
    const [loaderState, setLoaderState] = useState("");
    const firstActiveAccount = useRef(undefined);
    const [readCard, setReadCard] = useState(false);
    
    const setEditModalIsOpenToTrue =()=>{
        setEditModalIsOpen(true)
    }
    
    const setEditModalIsOpenToFalse =()=>{
        setEditModalIsOpen(false)
    }

    const setBlockModalIsOpenToTrue =()=>{
        setBlockModalIsOpen(true)
    }
    
    const setBlockModalIsOpenToFalse =()=>{
        setBlockModalIsOpen(false)
    }

    

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(34, 34, 34, 0.6)'
        },
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          backgroundColor       : '#fff',
          borderRadius          : '5px',
          border                : 'none',
          overflow              : 'visible'
        }
    }

   
    const toggleText = (state) => {
        if (state) {
            return parse(t("Accounts:see-closed"))
        } else {
            return parse(t("Accounts:see-active"))
        }
    }

   

    const PanelTop = (props) => {
        return (
            <>
            <AccountShortInfoMobile 
                firstactive={firstActiveAccount.current} 
                data={data} 
                {...props} 
            />
            <AccountBalance 
                firstactive={firstActiveAccount.current} 
                data={data} 
                {...props}
            />
            </>
        )
    }


    const isActiveAccount = (comptes, id) => {
        let activeState = false
        let realId = id !== undefined ? id : firstActiveAccount?.current


        for (let value in comptes) {
            if (Number(comptes[value].id) === Number(realId)) {
                if(comptes[value].cloturele === '') {
                    activeState = true
                    break
                }
            }
        }

        return activeState
    }

    const clickFirstVisible = () => {
            setTimeout(function() {
                    
            let accountsVisible = document.querySelectorAll(".accounts:not(.hide) .swiper-slide");

            if (accountsVisible.length > 0) {
                let account = accountsVisible[0].querySelector("a.account");
                
                if (account !== null) {
                    account.click();
                    window.location.reload()
                }
            }
        }, 150)
    }
               
    

    useEffect(() => {
        
        localStorage.removeItem("submitTimeLimit");
        localStorage.removeItem("trialLimit");
            
        const getAccounts = async () => {
            const token = JSON.parse(localStorage.getItem("token"));

            if (token !== null && !isExpired(token.data)) {

                const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;

                await fetch(REACT_APP_API_URL + "/client/" + token.user, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'Accept-Language'  : i18n.language,
                        'Content-Language'  : i18n.language,
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    }
                })
                .then(function(response){
                    return response.json();
                })
                .then(
                    (result) => {
                        if (result.reponse === "ok") {
                            setData(result.data)
                        } else {
                            clearLocalStorage()
                            props.history.push({pathname:"/login"})
                        }
                    },
                    (error) => {
                        return error
                    }
                )

            } else {
                // Error
            }
        }

        
        if (data === null) {
            getAccounts()
        }

    },[data, props.history, i18n.language])

    
    useEffect(() => {

        const getCards = async (id) => {
            const token = JSON.parse(localStorage.getItem("token"));
            if (!isExpired(token.data)) {

                const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
            
                await fetch(REACT_APP_API_URL + "/compte/" + id, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'Accept-Language'  : i18n.language,
                        'Content-Language'  : i18n.language,
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    }
                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {
                        if (result.reponse === "ok") {
                            setCards(result.data)
                            if (loaderState === "") {
                                setLoaderState("off")
                            }
                        }
                    },
                    (error) => {
                        return error
                    }
                )
                
            } else {
                // Error
            }
        }

        if (data !== null) {


            for (let obj in data.comptes) {
                if (data.comptes[obj].cloturele === "") {
                    firstActiveAccount.current = data.comptes[obj].id
                    break
                }
            }
        
            setToggleActiveAccount(isActiveAccount(data.comptes, id))
            
            
            //setCards(null)
            
            //if (cards !== null) { 

                
            //     if (props.id !== undefined) {
            //         if (cards.id !== props.id) {
            //             getCards(props.id);
            //         }
            //     } else {                    

            //         if (cards.id !== firstActiveAccount.current) {
            //             getCards(firstActiveAccount.current);
            //         }
            //     }
            
            // } else {
                if (url === "/dashboard") {
                    getCards(firstActiveAccount.current)
                } else {
                    getCards(props.id);
                }
            // }
            
            if (url !== "/dashboard" ) {
                setHideAccounts("hide hidden");
            } else {
                setHideAccounts("");
            }

           

        }

    },[data, props.id, id,  url, loaderState, i18n.language])


   


    let inactiveAccounts = 0;
    let activeAccounts = 0;

    if (data !== null && data !== undefined) {
        inactiveAccounts = getAccountsByStatus(data, false);
        activeAccounts = getAccountsByStatus(data, true);
    }
    
    return(
        <div className={`panels ${hideAccounts}`}>

            <div className={`loader ${loaderState}`} onTransitionEnd={(e) => {
                if (loaderState === "off") {
                    setLoaderState("off hidden");
                }
            }}>
                <img src={loader} alt={t("Accounts:loading")} width="100" height="100" />
            </div>

            <div className={`panel-1${props.transitionStage === "fadeIn" ? " hide" : ""}`} onTransitionEnd={(e) => {
                if (hideAccounts === "hide") {
                    setHideAccounts("hide hidden");
                }
            }}>
                <Logo className='logo desktop-high-only' />

                <LanguageSwitcher className="p1 mobile-all" />

                <ul className='dropdown-container user-menu user-menu-p1 box-shadow relative mobile-all'>
                {data !== null &&
                    <li className="current" onClick={(e) => {
                        toggleDropDown(".user-menu-p1")
                    }}>
                        <ul className="dropdown">
                            <li className="cl-grey-dark">{data.nomprenom}</li>
                            <li>
                                <NavLink to="/password-reset" onClick={(e) => {
                                    e.preventDefault()
                                    askNewPassword()
                                    toggleDropDown(".user-menu-p1")
                                }}>{parse(t("Accounts:password-change"))}</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={(e)=> {
                                    e.preventDefault()
                                    clearLocalStorage()
                                    props.history.push({
                                        pathname:"/login"
                                    })
                                }} to="/login">{parse(t("Accounts:logout"))}</NavLink>
                            </li>
                    
                        </ul>
                    </li>
                }
                    
                </ul>

                <Switch location={props.location}>
                    <Route path={`/dashboard/:id`} >
                        <div className="accounts-container">
                            <div className="app-title desktop-all">{parse(t('Login:app-title'))}</div>

                            <h2 className="mobile-all bolder-x">{parse(t("Accounts:my-account"))}</h2>

                            {activeAccounts === 0 && 
                            <div className={`accounts active ${toggleActiveAccount ? 'hide' : ''}`}>
                                <h3>{parse(t("Accounts:no-active-accounts"))}</h3>
                            </div>
                            }

                            {activeAccounts >= 1 &&
                            <div className={`accounts active ${toggleActiveAccount ? '' : 'hide'}`}>
                                <h3>{parse(t("Accounts:active-accounts"))}</h3>
                                <DisplayAccounts    
                                    loaderState={loaderState}
                                    hideAccounts={hideAccounts}
                                    id={id}    
                                    data={data}    
                                    active={true}  
                                    cards={cards}    
                                    {...props}
                                />
                            </div>
                            }

                            {inactiveAccounts === 0 && 
                            <div className={`accounts inactive ${toggleActiveAccount ? 'hide' : ''}`}>
                                <h3>{parse(t("Accounts:no-inactive-accounts"))}</h3>
                            </div>
                            }

                            {inactiveAccounts >= 1 &&
                            <div className={`accounts inactive ${toggleActiveAccount ? 'hide' : ''}`}>
                                <h3>{parse(t("Accounts:inactive-accounts"))}</h3>
                                <DisplayAccounts     
                                    loaderState={loaderState}
                                    hideAccounts={hideAccounts}
                                    id={id} 
                                    data={data} 
                                    cards={cards}  
                                    {...props} 
                                />
                            </div>
                            }
                        </div>
                    </Route>
                    <Route path={`/dashboard`} >
                        <div className="accounts-container">
                            <div className="app-title desktop-all">{parse(t('Login:app-title'))}</div>

                            <h2 className="mobile-all bolder-x">{parse(t("Accounts:my-account"))}</h2>
                            {activeAccounts === 0 && 
                            <div className={`accounts active ${toggleActiveAccount ? 'hide' : ''}`}>
                                <h3>{parse(t("Accounts:no-active-accounts"))}</h3>
                            </div>
                            }

                            {activeAccounts >= 1 &&
                            <div className={`accounts active ${toggleActiveAccount ? '' : 'hide'}`}>
                                <h3>{parse(t("Accounts:active-accounts"))}</h3>
                                <DisplayAccounts     
                                    loaderState={loaderState}
                                    hideAccounts={hideAccounts}
                                    id={id} 
                                    data={data} 
                                    cards={cards} 
                                    active={true} 
                                    {...props} 
                                />
                            </div>
                            }

                            {inactiveAccounts === 0 && 
                            <div className={`accounts inactive ${toggleActiveAccount ? 'hide' : ''}`}>
                                <h3>{parse(t("Accounts:no-inactive-accounts"))}</h3>
                            </div>
                            }

                            {inactiveAccounts >= 1 &&
                            <div className={`accounts inactive ${toggleActiveAccount ? 'hide' : ''}`}>
                                <h3>{parse(t("Accounts:inactive-accounts"))}</h3>
                                <DisplayAccounts 
                                    loaderState={loaderState}
                                    hideAccounts={hideAccounts}
                                    id={id} 
                                    data={data} 
                                    cards={cards}
                                    {...props} 
                                />
                            </div>
                            }
                        </div>
                    </Route>
                </Switch>
                

                <Menus 
                    firstactive={firstActiveAccount.current} 
                     
                    className="desktop-all" 
                    data={data} 

                /> 

                <div className={`toggle-accounts desktop-all ${toggleActiveAccount ? "active" : ''}`} onClick={(e) => {
                    setToggleActiveAccount(!toggleActiveAccount)
                    clickFirstVisible();
                }}>{toggleText(toggleActiveAccount)}</div>
                  
            </div>


            <div className="panel-2">
                
                <Switch location={props.location}>

                    <Route exact path={`/dashboard/:id/theft-loss`} >
                        <img className="desktop-all bg-solde" src={bgSolde} alt={parse(t("Accounts:theft-loss"))} width="320" height="923" />
                        <PanelTop {...props} />
                    </Route>

                    <Route exact path={`/dashboard/:id/reload`} >
                        <img className="desktop-all bg-payment" src={bgPayment} alt={parse(t("Payment:reload"))} width="320" height="923" />
                        <PanelTop {...props} />
                    </Route>

                    <Route exact path={`/dashboard/:id/refund`} >
                        <img className="desktop-all bg-refund" src={bgRefund} alt={parse(t("Refund:refund"))} width="320" height="923" />
                        <PanelTop {...props} />
                    </Route>

                    <Route exact path={`/dashboard/:id/contact`} >
                        <img className="desktop-all bg-contact" src={bgContact} alt={parse(t("Contact:contact"))} width="320" height="923" />
                        <PanelTop {...props} />
                    </Route>
                    
                    <Route path={`/dashboard/:id`}>
                        <PanelTop {...props} />
                        {!readCard &&
                        <CardLists
                            data={data} 
                            cards={cards} 
                            url={url} 
                            firstactive={firstActiveAccount.current} 
                            {...props}
                        />
                        }
                        {!readCard &&
                        <img className="desktop-all" src={familypicDesktop} alt={parse(t("Accounts:account-balance"))} width="225" height="312" />
                        }
                        <RFID
                            id={id}
                            setReadCard={setReadCard}
                            readCard={readCard}
                            openModal={setEditModalIsOpenToTrue}
                            history={props.history}
                            {...props}
                        />
                    </Route>

                    
                    
                    
                    <Route path={`/dashboard`}>
                        <PanelTop {...props} />
                        {!readCard &&
                        <CardLists 
                            data={data} 
                            cards={cards} 
                            url={url} 
                            firstactive={firstActiveAccount.current} 
                            {...props}
                        />
                        }
                        {!readCard &&
                            <img className="desktop-all" src={familypicDesktop} alt={parse(t("Accounts:account-balance"))} width="225" height="312" />
                        }
                        <RFID
                            id={id}
                            setReadCard={setReadCard}
                            readCard={readCard}
                            history={props.history}
                            openModal={setEditModalIsOpenToTrue}
                            {...props}
                        />
                    </Route>

                </Switch>


                

            </div>

            <div className={`panel-3`}>
                <LanguageSwitcher className="p3 desktop-all" />
                <AccountShortInfoDesktop firstactive={firstActiveAccount.current} data={data} {...props} />

                <Switch location={props.location}>
                    <Route path={`${url}/theft-loss`} >
                        <TheftLossModule 
                            cards={cards} 
                            setCards={setCards} 
                            hideAccounts={hideAccounts} 
                            loaderState={loaderState}
                            {...props} />
                    </Route>
                    <Route path={`${url}/reload`} >
                        <Payment 
                            cards={cards} 
                            url={url} 
                            setCards={setCards} 
                            hideAccounts={hideAccounts} 
                            loaderState={loaderState}
                            onSuccess={() => console.log('celebrate!')}
                            {...props} />
                    </Route>
                    <Route path={`${url}/refund`} >
                        <Refund 
                            cards={cards} 
                            setCards={setCards} 
                            setData={setData}
                            data={data}
                            hideAccounts={hideAccounts} 
                            loaderState={loaderState}
                            {...props} />
                    </Route>
                    <Route path={`${url}/contact`} >
                        <Contact 
                            data={data} 
                            cards={cards} 
                            hideAccounts={hideAccounts} 
                            loaderState={loaderState}
                            {...props} />
                    </Route>
                    <Route path={`${url}/:annexeid`} >
                        {!readCard &&
                            <Operations 
                                cards={cards} 
                                language={i18n.language} 
                                loaderState={loaderState}
                                toggleActiveAccount={toggleActiveAccount}
                                editModalIsOpen={editModalIsOpen}
                                blockModalIsOpen={blockModalIsOpen}
                                setEditModalIsOpenToTrue={setEditModalIsOpenToTrue} 
                                setBlockModalIsOpenToTrue={setBlockModalIsOpenToTrue} 
                                {...props} 
                            />
                        }
                    </Route>
                    <Route exact path={`${url}`} >
                        {!readCard &&
                            <Operations 
                                cards={cards} 
                                language={i18n.language} 
                                toggleActiveAccount={toggleActiveAccount}
                                loaderState={loaderState}
                                editModalIsOpen={editModalIsOpen}
                                blockModalIsOpen={blockModalIsOpen}
                                setEditModalIsOpenToTrue={setEditModalIsOpenToTrue} 
                                setBlockModalIsOpenToTrue={setBlockModalIsOpenToTrue} 
                                {...props}
                            />
                        }
                    </Route>
                </Switch>
            </div>

            <Menus data={data} readCard={readCard} history={props.history}/> 


            <Switch location={props.location}>

                <Route path={`/dashboard/:id/:annexeid`} >
                    <Modal 
                        isOpen={editModalIsOpen} 
                        style={customModalStyles} 
                        onRequestClose={()=> setEditModalIsOpen(false)} {...props}>
                        <button className="close-modal" onClick={() => {
                            if ("NDEFReader" in window) {
                                window.location.reload()
                            } else {
                                setEditModalIsOpenToFalse()
                            }
                        }}>x</button>
                        <picture>
                            <source media="(min-width: 1025px)" srcSet={editCardDesk} />
                            <img alt="" src={editCardMob} />
                        </picture>
                        <EditModal cards={cards} setCards={setCards} closeModal={setEditModalIsOpenToFalse} />
                    </Modal>

                    <Modal 
                        isOpen={blockModalIsOpen} 
                        style={customModalStyles} 
                        onRequestClose={()=> setBlockModalIsOpen(false)} {...props}>
                        <button className="close-modal" onClick={setBlockModalIsOpenToFalse}>x</button>
                        <picture>
                            <source media="(min-width: 1025px)" srcSet={blockCardDesk} />
                            <img alt="" src={blockCardMob} />
                        </picture>
                        <BlockModal cards={cards} setCards={setCards} closeModal={setBlockModalIsOpenToFalse} />
                    </Modal>
                </Route>
            </Switch>
           
           
        </div>
    )
}

export default Accounts