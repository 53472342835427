//-> Gestion multilingue

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import { toggleRecaptcha } from '../components/Helpers';
import XHR from "i18next-http-backend";

i18n.on('initialized', function (lng) {});

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    react: { 
      useSuspense: false
    },
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    resources: {
    en: {
      Login: require('./locales/en/Login.json'),
      ResetPassword: require('./locales/en/ResetPassword.json'),
      ForgotPassword: require('./locales/en/ForgotPassword.json'),
      FormErrors: require('./locales/en/FormErrors.json'),
      FormSuccess: require('./locales/en/FormSuccess.json'),
      Dashboard: require('./locales/en/Dashboard.json'),
      Menu: require('./locales/en/Dashboard/Menu.json'),
      Accounts: require('./locales/en/Dashboard/Accounts.json'),
      EditModal: require('./locales/en/Dashboard/EditModal.json'),
      BlockModal: require('./locales/en/Dashboard/BlockModal.json'),
      Theft: require('./locales/en/Dashboard/Theft.json'),
      Payment: require('./locales/en/Dashboard/Payment.json'),
      Refund: require('./locales/en/Dashboard/Refund.json'),
      Contact: require('./locales/en/Dashboard/Contact.json'),
      NotFound: require('./locales/en/NotFound.json'),
      RFID: require('./locales/en/Dashboard/RFID.json')
    },
    es: {
      Login: require('./locales/es/Login.json'),
      ResetPassword: require('./locales/es/ResetPassword.json'),
      ForgotPassword: require('./locales/es/ForgotPassword.json'),
      FormErrors: require('./locales/es/FormErrors.json'),
      FormSuccess: require('./locales/es/FormSuccess.json'),
      Dashboard: require('./locales/es/Dashboard.json'),
      Menu: require('./locales/es/Dashboard/Menu.json'),
      Accounts: require('./locales/es/Dashboard/Accounts.json'),
      EditModal: require('./locales/es/Dashboard/EditModal.json'),
      BlockModal: require('./locales/es/Dashboard/BlockModal.json'),
      Theft: require('./locales/es/Dashboard/Theft.json'),
      Payment: require('./locales/es/Dashboard/Payment.json'),
      Refund: require('./locales/es/Dashboard/Refund.json'),
      Contact: require('./locales/es/Dashboard/Contact.json'),
      NotFound: require('./locales/es/NotFound.json'),
      RFID: require('./locales/es/Dashboard/RFID.json')
    },
    fr: {
      Login: require('./locales/fr/Login.json'),
      ResetPassword: require('./locales/fr/ResetPassword.json'),
      ForgotPassword: require('./locales/fr/ForgotPassword.json'),
      FormErrors: require('./locales/fr/FormErrors.json'),
      FormSuccess: require('./locales/fr/FormSuccess.json'),
      Dashboard: require('./locales/fr/Dashboard.json'),
      Menu: require('./locales/fr/Dashboard/Menu.json'),
      Accounts: require('./locales/fr/Dashboard/Accounts.json'),
      EditModal: require('./locales/fr/Dashboard/EditModal.json'),
      BlockModal: require('./locales/fr/Dashboard/BlockModal.json'),
      Theft: require('./locales/fr/Dashboard/Theft.json'),
      Payment: require('./locales/fr/Dashboard/Payment.json'),
      Refund: require('./locales/fr/Dashboard/Refund.json'),
      Contact: require('./locales/fr/Dashboard/Contact.json'),
      NotFound: require('./locales/fr/NotFound.json'),
      RFID: require('./locales/fr/Dashboard/RFID.json')
    },
    it: {
      Login: require('./locales/it/Login.json'),
      ResetPassword: require('./locales/it/ResetPassword.json'),
      ForgotPassword: require('./locales/it/ForgotPassword.json'),
      FormErrors: require('./locales/it/FormErrors.json'),
      FormSuccess: require('./locales/it/FormSuccess.json'),
      Dashboard: require('./locales/it/Dashboard.json'),
      Menu: require('./locales/it/Dashboard/Menu.json'),
      Accounts: require('./locales/it/Dashboard/Accounts.json'),
      EditModal: require('./locales/it/Dashboard/EditModal.json'),
      BlockModal: require('./locales/it/Dashboard/BlockModal.json'),
      Theft: require('./locales/it/Dashboard/Theft.json'),
      Payment: require('./locales/it/Dashboard/Payment.json'),
      Refund: require('./locales/it/Dashboard/Refund.json'),
      Contact: require('./locales/it/Dashboard/Contact.json'),
      NotFound: require('./locales/it/NotFound.json'),
      RFID: require('./locales/it/Dashboard/RFID.json')
    }
  },
  whitelist: ['fr', 'en', 'es', 'it'],
  ns: [
    'Login', 
    'ResetPassword', 
    'Footer', 
    'ForgotPassword', 
    'FormErrors', 
    'FormSuccess', 
    'Dashboard', 
    'Menu', 
    'Accounts', 
    'EditModal', 
    'BlockModal', 
    'Theft', 
    'Payment', 
    'Refund', 
    'Contact', 
    'NotFound'
  ],
  detection: {
    order: ["localStorage", "cookie", "navigator"],
    caches: ["localStorage", "cookie"],
    lookupFromPathIndex: 0,
    checkWhitelist: true
  },
  debug:false,
  interpolation: {
    escapeValue: false,
    formatSeparator: '.'
  }
});

i18n.languages = ['fr', 'en', 'es', 'it'];

i18n.on('languageChanged', function (lng) {
  if (window.location.href.indexOf("dashboard") !== -1) {
    setTimeout(function() {
      toggleRecaptcha(false)
    }, 150)
  }
})

export const getLanguage = () => {
  if(i18n.language !== "fr") {
    return '/' + i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    '';
  } else {
    return '';
  }
}


export const getLanguageCode = () => {
    return i18n.language;
}

export const baseRouteUrl = i18n.language === "fr" ? "" : "/" + i18n.language
export default i18n
