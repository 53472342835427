import React, { useRef, useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { isExpired } from "react-jwt";
import parse from "html-react-parser";
import '../../assets/css/elements/Dashboard/RFID.css';
import bgWaiting from '../../assets/images/nfc-wait.jpg';
import bgLoader from '../../assets/images/loader-bracelet.svg';

const RFID = (props) => {
    const { i18n, t } = useTranslation();
    const { id } = useParams();
    const { history, setReadCard, readCard, openModal } = props;
    const timerRef = useRef(null);
    const showTimerRef = useRef(null);
    const showMessageRef = useRef(null);
    const [counter, setCounter] = useState(5);
    const [showTimer, setShowTimer] = useState(false);
    const [message, setMessage] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [returnClass, setReturnClass] = useState(null);
    const abortController = new AbortController();

    const onReading = ({message, serialNumber}) => {
        if (serialNumber !== null) {
            let serial = serialNumber.toString().replace(/:/gi, "").toUpperCase();
            getCardInfo(serial);
        } else {
            setReadCard(false);
        }
    };

    const onReadingError = () => {
        resetReader("RFID:reading-error", "error");
    };

    const getCardInfo = async(uuid) => {
        const token = JSON.parse(localStorage.getItem("token"));

        if (token !== null && !isExpired(token.data)) {

            const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
        
            await fetch(REACT_APP_API_URL + "/identificationuuid/" + id + "/" + uuid.toUpperCase(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept' : '*/*',
                    'Accept-Language'  : i18n.language,
                    'Content-Language'  : i18n.language,
                    'X-API-KEY': REACT_APP_API_KEY,
                    "Authorization" : "Bearer " + token.data
                }
            })
            .then(function(response){
                return response.json();
            })
            .then(
                (result) => {
                    if (result.reponse === "ok") {
                        
                        let annexeid = 0;
                        let url = "/dashboard";

                        if (result.reponsetexte === "annexe") {
                            annexeid = result.data.annexeid;
                        }

                        url += "/" + id + "/" + annexeid
                        
                        resetReader("RFID:new-bracelet", "success");

                        setTimeout(function() {
                            history.push({
                                pathname: url
                            })
                            openModal(true)

                        }, 2000)
                    }

                    if (result.reponse === "Forbidden") {
                        resetReader("RFID:api-error", "error");
                    }

                    if (result.reponse === "Not Found") {
                        resetReader("RFID:wrong-uuid", "error");
                    }

                },
                (error) => {
                    console.log(error)
                    return error
                }
            )

        } else {
            <Redirect to="/login" />
        }
    };

    const resetView = (result) => {

        showMessageRef.current = setTimeout(function() {
            setReadCard(false);
            setCounter(5);
            setShowTimer(false);
            setShowMessage(false);
            clearTimeout(timerRef.current);
            clearTimeout(showTimerRef.current);
            clearTimeout(showMessageRef.current);
            
            if (result === "error") {
                window.location.reload();
            } else {
                abortController.abort();
            }
        }, 2000);
    }

    const resetReader = (message, result) => {
        setShowMessage(true);
        setReturnClass(result);
        setMessage(parse(t(message)));
        resetView(result);
    };

    const scan = async() => {
        try {
            if ("NDEFReader" in window) {
                
                let ndef = new window.NDEFReader();

                await ndef.scan({signal:abortController.signal}).then(() => {
                    
                    setReadCard(true);

                    ndef.onreading = (event) => {
                        onReading(event)
                    };

                    ndef.onreadingerror = (event) => {
                        onReadingError(event)
                    };
                    
                    showTimerRef.current = setTimeout(function() {
                        setShowTimer(true);
                    }, 10000);

                    timerRef.current = setTimeout(function() {
                        resetView();
                    }, 15000);

                }).catch(error => {
                    console.log(`Error: ${error}.`);
                    resetReader(error, "error");
                });
            }

        } catch (error) {
            resetReader("RFID:scan-error", "error");
            window.location.reload();
        }
    };
        

    useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, [timerRef]);

    useEffect(() => {
        return () =>  clearTimeout(showTimerRef.current);
    }, [showTimerRef]);


    useEffect(() => {
        return () =>  clearTimeout(showMessageRef.current);
    }, [showMessageRef]);

    useEffect(() => {
        
        let timer;

        if (showTimer)  {
            timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        }
        
        return () => clearInterval(timer);
      
    }, [counter, showTimer]);

    
    return(
        <>
            {!readCard && ("NDEFReader" in window) &&
                <div className="rfid-button mobile-all" onClick={async() => {
                    await scan();
                }}>{parse(t("RFID:read-card"))}</div>
            }
        
            {readCard && 
                <div className="waiting-card text-center bold">

                    {!showTimer && !showMessage &&
                        <img className="mobile-all reading-loader" src={bgLoader} alt={parse(t("RFID:waiting"))} width="50" height="60" />
                    }
                    {showTimer && !showMessage && 
                        <><br/>
                        <div className="counter">{counter}</div>
                        </>
                    }

                    {showMessage &&
                        <div className={`message box-shadow ${(returnClass)}`}>{message}</div>
                    }

                    {!showMessage &&
                    <>
                        <p>{parse(t("RFID:waiting-for-card"))}</p>
                        <p><img className="mobile-all bg-wait" src={bgWaiting} alt={parse(t("RFID:waiting"))} width="250" height="203" /></p>
                    </>
                    }
                </div>
            }
        </>
    )

}

export default RFID