import React, { Component } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import FormErrors from '../components/FormErrors';
import FormSuccess from '../components/FormSuccess';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { handleUserInput, errorClass, onFieldKeyDown } from '../components/Helpers';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import '../assets/css/general/connexion.css';

class ForgotPassword extends Component {

    constructor(props) {
      super(props);

      this.state = {
        email: '',
        formErrors: {email: '', error: ''},
        formSuccess: false,
        successMessage: '',
        emailValid: false,
        formValid: false
      };
        
      this.handleSubmit = this.handleSubmit.bind(this);
    }
       

    validateField(fieldName, value) {
      
      const {t} = this.props;
      let emailValid = this.state.emailValid;
      let fieldValidationErrors = this.state.formErrors;

      switch(fieldName) {
        case 'email':
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          fieldValidationErrors.email = (emailValid || value === "") ? '' : t('FormErrors:invalid-email');
        break;
        case 'error':
          fieldValidationErrors.error = value
        break;
    
        default:
          break;
      }
      
      this.setState({
        formErrors: fieldValidationErrors,
        emailValid: emailValid
      }, this.validateForm);
    }
  
    validateForm() {
      this.setState({
        formValid: this.state.emailValid
      });
    }
  

    queryApi = async (email) =>  {
      const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
      const {t, history} = this.props;

      await fetch(REACT_APP_API_URL + "/demandechangementpassword", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': REACT_APP_API_KEY
        },
        body: JSON.stringify({
          "mail": email
        })
      })
      .then(res => res.json())
      .then(
        (result) => {

          let submitTimeLimit = Number(localStorage.getItem('submitTimeLimit'));
          let currentDate = new Date();
          let minutesToAdd = 2;
          let futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);

          if (submitTimeLimit === 0) {
            localStorage.setItem('submitTimeLimit', futureDate.getTime());
          }

          if (result.reponse === "ok") {
            this.setState({
              formSuccess: true,
              successMessage: t("FormSuccess:forgot-password-success")
            })

            setTimeout(function() {
              history.push({
                pathname: "/login"
              })
            }, 2000)

          } else {
            this.setState({
              formErrors: {error:result.reponsetexte}
            });
          }
        },
        (error) => {
          this.setState({
            formErrors: {
              error: error
            }
          });
        }
      )
    }

    onVerify = async () => {
      const { t } = this.props;
      const { executeRecaptcha } = this.props.googleReCaptchaProps;
      
      if (!executeRecaptcha) {
        console.log('Recaptcha has not been loaded');
        return;
      }
  
      const token = await executeRecaptcha('ForgotPassword');

      if(this.state.formValid && token !== null) {
        let submitTimeLimit = Number(localStorage.getItem('submitTimeLimit'));
        
        if (submitTimeLimit !== 0) {
          let currentDate = new Date();

          if (currentDate.getTime() > submitTimeLimit) {
            localStorage.removeItem('submitTimeLimit')
            localStorage.removeItem('trialLimit')
            this.queryApi(this.state.email)
          } else {
            let endDate = new Date(submitTimeLimit);
            let minutesLeft = parseInt(Math.abs(endDate.getTime() - currentDate.getTime()) / (1000 * 60) % 60);

            this.setState({
              formErrors: {error:t("FormErrors:password-limit", {
                minutesLeft : minutesLeft+1
              })}
            })
          }
        } else {
          this.queryApi(this.state.email)
        }
        
      } else {
        this.setState({
          formErrors: {error:t("FormErrors:empty-email")}
        })
      }
    }
    
    handleSubmit(e) {
      e.preventDefault()
      this.setState({
        formErrors: {},
        formSuccess: false
      })
      this.onVerify();
    }

    render () {
      const {i18n, t} = this.props;

      return (
        <div className="connect">
        
          <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
            <title>{t('ForgotPassword:title')}</title>
            <meta name="description" content={t("ForgotPassword:desc")} />
            </Helmet>
          </HelmetProvider>

          <LanguageSwitcher />

          <div className="wrapper">
            
            <form onSubmit={this.handleSubmit}>
            
              <Logo className='logo'/>

              <div className="form">

                <div className="title">{t('ForgotPassword:page-title')}</div>
                <p className="text-center">{t('ForgotPassword:page-subtitle')}</p>
              
                <FormErrors formErrors={this.state.formErrors} />
                <FormSuccess formSuccess={this.state.formSuccess} successMessage={this.state.successMessage} />
                
                <div className="fields">
                  <div className={`form-group ${errorClass(this.state.formErrors.email)}`}>
                    <label htmlFor="email">{t('ForgotPassword:email-address')}
                      <input type="email" required className="form-control" name="email"
                        placeholder={t('ForgotPassword:enter-email')}
                        value={this.state.email}
                        onChange={
                          (e) => {
                            handleUserInput(e, this)
                          }
                        } onKeyDown={
                            (e) => {
                              onFieldKeyDown(e, this)
                            }
                          } />
                      </label>
                  </div>
                
                  <Link className="nav-link text-left" to="/login">{t('ForgotPassword:back-to-login')}</Link>
                </div>
              </div>
              <button type="submit" className="continue" disabled={!this.state.formValid}>{t('ForgotPassword:continue')}</button>
            </form>
          </div>
        </div>
      )
    }
}

export default withTranslation()(withGoogleReCaptcha(ForgotPassword));
