import React from 'react';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';

//-> Gestion des messages de succès sur le formulaire de login
const FormSuccess = ({formSuccess = false, successMessage = ''}) => {
    
    return (
        <div className='form-success'>
            {formSuccess && 
                <p className="bg-success2 text-center"><span className='valid'></span>{parse(successMessage)}</p>
            }
        </div>
    )
}

export default withTranslation()(FormSuccess)