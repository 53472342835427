//-> Gestion du menu principal

import Menu from './Menu';
import { Switch, Route } from "react-router-dom";


const Menus = (props) => {
    const { data, location, firstactive } = props;
    return(
        <Switch location={location}>
            {data !== null &&
            <Route exact path={`/dashboard`}>
                <Menu firstactive={firstactive} {...props} />
            </Route>
            }
            <Route path={`/dashboard/:id`}>
                <Menu firstactive={firstactive} {...props} />
            </Route>
        </Switch>
    )
}

export default Menus