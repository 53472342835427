//-> Boîte de dialogue de modification du nom d'un bracelet

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isExpired } from "react-jwt";
import { replaceDot } from "../../components/Helpers";
import parse from 'html-react-parser';
import { t } from "i18next";
import '../../assets/css/elements/Dashboard/Modal.css';

const EditModal = (props) => {
  const { id, annexeid } = useParams();
  const [currentName, setCurrentName] = useState("");
  const [currentLimitType, setCurrentLimitType] = useState("");
  const [currentLimitAmount, setCurrentLimitAmount] = useState("");
  const [newName, setNewName] = useState("");
  const [newLimitType, setNewLimitType] = useState("");
  const [newLimitAmount, setNewLimitAmount] = useState("");
  const [changeResult, setChangeResult] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { cards, setCards, closeModal } = props;
  // const [soldeMax, setSoldeMax] = useState("")

 
  useEffect(() => {
    if (cards !== undefined) {
      Object.keys(cards.codesannexes).forEach((i) => {
          if (annexeid !== undefined) {
              if (Number(cards.codesannexes[i].annexeid) === Number(annexeid)) {

                if (cards.codesannexes[i].texteclient === "") {

                  if (cards.codesannexes[i].textepms === "") {
                    setCurrentName(parse(t("Accounts:card") + " " + (Number(i)+1)))
                  } else {
                    setCurrentName(parse(cards.codesannexes[i].textepms))
                  }
                } else {
                  setCurrentName(parse(cards.codesannexes[i].texteclient))
                }

                if (Number(annexeid) !== 0) {
                  let limit = cards.codesannexes[i].limitetype
                  if(limit === "") {
                    limit = "aucune"
                  }

                  let amount = cards.codesannexes[i].limitemontant
                  
                  if(amount === "") {
                    amount = "0"
                  }

                  setCurrentLimitType(parse(limit))
                  setCurrentLimitAmount(parse(replaceDot(amount)))
                  // setSoldeMax(cards.solde)

                  if (amount === "0" || limit === "aucune") {
                    setIsDisabled(true)
                  } else {
                    setIsDisabled(false)
                  }
                }

              }

              if (Number(annexeid) === 0) {
                setCurrentName(cards.texteclient)
              }
          }
          
      })
    }

  }, [annexeid, cards])

  const changeCardData = async (name, limitType, limitAmount = "0") => {
    const token = JSON.parse(localStorage.getItem("token"));

    if (!isExpired(token.data)) {

        const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
        let url = ""

        if (limitAmount === "0") {
          limitType = "aucune"
        }

        if (Number(annexeid) === 0) {
          url = id
        } else {
          url = id + "/" + annexeid
        }

        let obj = {}

        if (name !== "") {
          obj.texteutilisateur = name
        }

        if (limitType !== "" && limitAmount !== "") {
          obj.limitetype = limitType
          obj.limitemontant = limitAmount
        }

        if (obj.texteutilisateur !== "" || (obj.limitetype !== "" && obj.limitemontant !== "")) {

          await fetch(REACT_APP_API_URL + "/compte/" + url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept' : '*/*',
                'X-API-KEY': REACT_APP_API_KEY,
                "Authorization" : "Bearer " + token.data
            },
            body: JSON.stringify(obj)
          }).then(function(response){
              return response.json();
          }).then(
              (result) => {
                  if (result.reponse === "ok") {
                      if (cards !== null) {

                        if (Number(annexeid) === 0) {

                          cards.texteclient = result.data.texteclient
                          setCurrentName(result.data.texteclient)
                          
                        } else {
                            Object.keys(cards.codesannexes).forEach((i) => {
                              if (annexeid !== undefined) {
                                  if (Number(cards.codesannexes[i].annexeid) === Number(annexeid)) {
                                    cards.codesannexes[i].texteclient = result.data.texteclient
                                    cards.codesannexes[i].limite = result.data.limite
                                    cards.codesannexes[i].limitetype = result.data.limitetype
                                    cards.codesannexes[i].disponible = result.data.disponible
                                    cards.codesannexes[i].limitemontant = result.data.limitemontant
                                    setCurrentName(result.data.texteclient)
                                    setCurrentLimitType(result.data.limitetype)
                                    setCurrentLimitAmount(replaceDot(result.data.limitemontant))

                                    if (result.data.limitetype === "aucune") {
                                      setIsDisabled(true)
                                      setCurrentLimitAmount("0")
                                    }
                                  }
                              }
                              
                          })
                        }

                        setCards(cards);

                        setChangeResult(parse(t("EditModal:cardname-changed")))
                        
                        setTimeout(function() {
                          if ("NDEFReader" in window) {
                            window.location.reload()
                          } else {
                            closeModal()
                          }
                        }, 800);
                        
                      } else {
                        setChangeResult(parse(t("BlockModal:error-cards")))
                      }
                  }
              },
              (error) => {
                  return error
              }
          )
        } else {
          if ("NDEFReader" in window) {
            window.location.reload()
          } else {
            closeModal()
          }
          
        }
        
        
    } else {
        // Error
    }
  }

    const handleSubmit = (e) => {
        e.preventDefault()

        let tmpLimitType = "";
        let tmpLimitAmount = "";
  
        //-> Si le type de limite pas mis à jour
        if (newLimitType === "") {

          //-> Si le montant mis à jour
          if (newLimitAmount !== "") {
            tmpLimitType = currentLimitType
            tmpLimitAmount = newLimitAmount.replace(",", ".")
          }
          
        } else {

          tmpLimitType = newLimitType

          //-> Si le montant pas mis à jour
          if (newLimitAmount === "") {
              tmpLimitAmount = currentLimitAmount.replace(",", ".")
          } else {
            tmpLimitAmount = newLimitAmount.replace(",", ".")
          }

        }
        
        if ((tmpLimitAmount === "0" || tmpLimitAmount === "") && tmpLimitType !== "aucune" && tmpLimitType !== "") {
          setChangeResult("error-submit-no-amount")
        } else {
          if (
            newName !== "" || 
            (tmpLimitType !== "" && tmpLimitAmount !== "")
          ) {
            // if (parseFloat(tmpLimitAmount.replace(",", ".")) <= parseFloat(soldeMax)) {
            // changeCardData(newName, newLimitType, newLimitAmount.replace(",", "."))
            // }

            changeCardData(newName, tmpLimitType, tmpLimitAmount.replace(",", "."))

          } else {
            if ("NDEFReader" in window) {
              window.location.reload()
            } else {
              closeModal()
            }
            
          }
        }


        
    }

    const onEnterPress = (e) => {
      if (e.key === "Enter") {
        handleSubmit(e)
      }
    }

    
    // erreur quand on passe de jour a sejour, ça remet à zéro
    // Prévoir un message d'erreur quand le montant est à 0 et que la limite est autre qu'aucune
    // Empecher qu'une 
      return (
        <div className="modal-inner relative">
          <div className="modal-title bolder-x">{parse(t("Accounts:edit-card"))}</div>
          <div className="current-name bolder-x cl-turquoise">{parse(currentName)}</div>
          <div className="message">{parse(t("EditModal:edit-card-confirm"))}</div>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="texteutilisateur">{parse(t("EditModal:new-card-name"))}<br/>
                <input type="text" maxLength="80" placeholder={parse(currentName)} className="form-control" name="texteutilisateur" id="texteutilisateur" onChange={e => {

                  if (e.target.value.length >= 80) {
                    setChangeResult("error-input")
                  } else {
                    setChangeResult("")
                  }
                  setNewName(e.target.value)
                  
                }} onKeyDown={onEnterPress} />
              </label>
          </div>

          {Number(annexeid) !== 0 &&
            <div className="form-group limit text-left">
                <p>{parse(t("Accounts:limit"))}</p>
                <div className="labels flex flex-direction-row flex-wrap">
                  <label htmlFor="aucune" className="group-item flex flex-direction-row align-items-center relative">
                    <div className="label-styled bg-grey-light relative">
                        <input type="checkbox" id="aucune" name="aucune" value="aucune" checked={(newLimitType === "" ? currentLimitType === "aucune" : newLimitType === "aucune")} onChange={e => {
                          setNewLimitType(e.target.value)
                          setIsDisabled(true)
                          setNewLimitAmount("0")
                          setChangeResult("");
                        }} />
                        <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="aucune"></label>
                    </div>
                    <span>{parse(t("Accounts:no-limit-modal"))}</span>
                  </label>

                  <label htmlFor="jour" className="group-item flex flex-direction-row align-items-center relative">
                    <div className="label-styled bg-grey-light relative">
                      <input type="checkbox" id="jour" name="jour" value="jour" checked={(newLimitType === "" ? currentLimitType === "jour" : newLimitType === "jour")} onChange={e => { 
                        setNewLimitType(e.target.value)
                        setIsDisabled(false)
                        setNewLimitAmount(currentLimitAmount)
                        // if (parseFloat(currentLimitAmount.replace(",", ".")) > parseFloat(soldeMax)) {
                        //   setChangeResult("amount-exceeded")
                        // }
                      }} />

                        <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="jour"></label>
                    </div>
                    <span>{parse(t("Accounts:daily-limit-modal"))}</span>
                  </label>

                  <label htmlFor="sejour" className="group-item flex flex-direction-row align-items-center relative">
                    <div className="label-styled bg-grey-light relative">
                      <input type="checkbox" id="sejour" name="sejour" value="sejour" checked={(newLimitType === "" ? currentLimitType === "sejour" : newLimitType === "sejour")} onChange={e => {    setNewLimitType(e.target.value)
                        setIsDisabled(false)
                        setNewLimitAmount(currentLimitAmount)
                        // if (parseFloat(currentLimitAmount.replace(",", ".")) > parseFloat(soldeMax)) {
                        //   setChangeResult("amount-exceeded")
                        // }
                      }} />

                        <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="sejour"></label>
                    </div>
                    <span>{parse(t("Accounts:stay-limit-modal"))}</span>
                  </label>
                </div>

                <div className="input">
                  <p>{parse(t("EditModal:limit-amount"))}<input type="text" 
                  value={isDisabled ? "0" : (newLimitAmount !== "" ? newLimitAmount : currentLimitAmount)} 
                  onChange={e => {

                    // let filteredVal = e.target.value.replace(".", ",");
                    let filteredVal = e.target.value;
                    setCurrentLimitAmount(filteredVal)
                    setNewLimitAmount(filteredVal)

                    // if (parseFloat(filteredVal.replace(",", ".")) > parseFloat(soldeMax)) {
                    //   setChangeResult("amount-exceeded")
                    // }

                    // if (filteredVal !== "0" && filteredVal !== "" && parseFloat(filteredVal.replace(",", ".")) <= parseFloat(soldeMax)) {

                    if (filteredVal !== "0" && filteredVal !== "") {
                      setChangeResult("")
                    }
                  }}

                  onFocus={e => {
                    setIsFocused(true)
                  }}

                  onBlur={e => {
                    setIsFocused(false)
                  }}

                  disabled={isDisabled} className={(isDisabled ? "disabled" : "") + (isFocused ? " focus" : "")} /><span className={(isDisabled ? "disabled" : "") + (isFocused ? " focus" : "")}>{(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")}</span>
                  </p>
                </div>
            </div>
          }

          <div className="buttons relative flex flex-direction-row align-items-center justify-content-evenly">
            <button type="submit" onClick={handleSubmit} className="modal-button action">{parse(t("EditModal:apply"))}</button>
            <button type="button" onClick={() => {
              
              if ("NDEFReader" in window) {
                window.location.reload()
              } else {
                closeModal()
              }
              
            }} className="modal-button cancel" >{parse(t("EditModal:cancel"))}</button>
          </div>
          
          {/* {changeResult !== "" && changeResult !== "error-input" && changeResult !== "error-submit-no-amount" && changeResult !== "amount-exceeded" && */}

          {changeResult !== "" && changeResult !== "error-input" && changeResult !== "error-submit-no-amount" &&
            <div className='form-success'>
              <p className="bg-success2 text-center"><span className='valid'></span>{parse(changeResult)}</p>
            </div>
          }

          {/* {changeResult !== "" && (changeResult === "error-input" || changeResult === "error-submit-no-amount" || changeResult === "amount-exceeded") && */}
          {changeResult !== "" && (changeResult === "error-input" || changeResult === "error-submit-no-amount") &&
            <div className='form-errors'>
              <p className="bg-error2 text-center">
                <strong>{parse(t("FormErrors:error").toUpperCase())}</strong>&nbsp;
                {parse(t("EditModal:" + changeResult))}
              </p>
            </div>
          }

          </form>
        </div>
      );
  }
  
export default EditModal