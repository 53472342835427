import i18n from 'i18next';
import parse from "html-react-parser";
import { useEffect } from 'react';
import { isExpired } from "react-jwt"

//-> Mapping des langues
export const langs = {
  fr : {
    name : "français",
    flag : "FR"
  },

  en : {
    name : "english",
    flag : "GB"
  },

  es : {
    name : "español",
    flag : "ES"
  },

  it : {
    name : "italiano",
    flag : "IT"
  }
    
}

//-> Fonction de vérification du mot de passe au 1er login
export const CheckPasswordStrength = (value) => {

    let stringValues = { 
      length: value.length >= 10 ? "valid" : "not-valid", 
      uppercase: value.match( /[A-Z]/g) ? "valid" : "not-valid",
      lowercase: value.match(/[a-z]/g) ? "valid" : "not-valid",
      number: value.match( /[0-9]/g) ? "valid" : "not-valid",
      empty: value === "" ? "not-valid" : "valid"
    }

    let outString = i18n.t('FormErrors:password-requirements', stringValues)

    let valid = true;

    Object.keys(stringValues).forEach((criteria, i) => {
      if(stringValues[criteria] === "not-valid"){
        valid = false
      }
    })

    return [outString, valid]
}

//-> Fonction de vérification de la saisie des champs
export const handleUserInput = (e, component) => {

  const name = e.target.name
  const value = e.target.value

  component.setState({
    formErrors: {},
    formSuccess: false
  })

  component.setState({[name]: value}, () => { 
    component.validateField(name, value) 
  });
}

//-> Envoi des formulaires lors de l'appui sur la touche Enter
export const onFieldKeyDown = (e, component) => {
  if (e.key === "Enter" && component !== null) {
    component.handleSubmit(e)
  }
}

//-> Afficher/masquer la dropdown des langues/options de login
export const toggleDropDown = (selector) => {
  
  if (selector !== null) {
    if(document.querySelector(selector + " .dropdown") !== null) {
      let dropdown = document.querySelector(selector + " .dropdown");
  
      if (!dropdown.classList.contains("on")) {
        dropdown.classList.add("visible")
        setTimeout(function() {
          dropdown.classList.add("on")
        }, 100)
      } else {
        dropdown.classList.remove("on")
        setTimeout(function() {
          dropdown.classList.remove("visible")
        }, 150)
      }
  
    }
  }
 
}

//-> Ajout d'une classe has-error
export const errorClass = (error) => {
  return(error === '' ? '' : 'has-error');
}

//-> Fix vh sur mobiles
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

//-> Récupérer le token JWT reçu de chez Osmozis
export const getToken = () => {
  let token = JSON.parse(localStorage.getItem("token"))

  if (token.data !== null) {
    return token.data
  }
}

//-> Afficher/masquer le recaptcha
export const toggleRecaptcha = (show) => {
  
  let recaptchaBadge = document.querySelector(".grecaptcha-badge");

  if (recaptchaBadge !== null) {

    if (show) {
      recaptchaBadge.style.display = "block"
    } else {
      recaptchaBadge.style.display = "none"
    }
  }
}

//-> Récupérer un compte via l'API avec son ID numérique
export const getAccountById = (comptes, id) => {

  let account = null
  
  if (comptes?.length > 0) {
    for (let obj in comptes) {
      let value = comptes[obj];
      if (Number(value.id) === Number(id)) {
          account = value
          break
      }
    }
  }

  return account
  
}

//-> Insert d'un script JS dans le DOM, compatible React
export const useScript = (id, url, integrity, crossOrigin) => {
  useEffect(() => {
    const script = document.createElement('script');

    if (document.getElementById(id) === null) {
      script.id = id;
      script.src = url;
      script.async = true;
      script.integrity = integrity;
      script.crossOrigin = crossOrigin;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [id, url, integrity, crossOrigin]);
};

//-> Formattage simple de la date à partir d'un timestamp
export const formatDate = (date) => {
  let d = date.substring(6, 8)
  let m = date.substring(4, 6)
  let y = date.substring(0, 4)
  let tmpDate = new Date(+y, m-1, +d)
  let fDate = "";

  if (tmpDate !== "Invalid Date") {
      fDate = new Intl.DateTimeFormat(i18n.language, {
          year: "numeric",
          month: "long",
          day: "2-digit"
      }).format(tmpDate)
  }

  return fDate
}

//-> Fonction appelée pour la demande d'un nouveau mot de passe pour le compte
export const formatFullDate = (date) => {
  let d = date.substring(6, 8)
  let m = date.substring(4, 6)
  let y = date.substring(0, 4)
  let h = date.substring(8, 10)
  let min = date.substring(10, 12)
  let s = date.substring(12, 14)
  let tmpDate = new Date(+y, m-1, +d, +h, +min, +s)
  let fDate = "";

  if (tmpDate !== "Invalid Date") {
      fDate = new Intl.DateTimeFormat(i18n.language, {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false
      }).format(tmpDate)
  }

  return fDate.replace(',','').replace('à','')
}

//-> Renvoyer les comptes selon leur statut (clôturé/non-clôturé)
export const askNewPassword = async () =>  {
  const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
  const token = JSON.parse(localStorage.getItem("token"));

  if (!isExpired(token.data)) {
      const email = token.user

      if (email !== null) {
          await fetch(REACT_APP_API_URL + "/demandechangementpassword", {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'X-API-KEY': REACT_APP_API_KEY
              },
              body: JSON.stringify({
              "mail": email
              })
          })
          .then(res => res.json())
          .then(
              (result) => {
      
              if (result.reponse === "ok") {
                  alert(i18n.t("Accounts:password-request-sent"))
              }
              },
              (error) => {
                  alert(i18n.t("FormErrors:cant-perform-request"))
              }
          )
      } else {
          alert(i18n.t("FormErrors:email-issue"))
      }
  } else {
      alert(i18n.t("FormErrors:expired-token"))
  }
}

//-> Suppression des variables stockées avec localStorage
export const getAccountsByStatus = (data, active) => {
  let result = null;

  if (data !== null) {
      result = data.comptes.filter((value) => {
          return active ? value.cloturele === '' : value.cloturele !== ''
      })

      if (result != null) {
          return result.length
      } else {
          return 0
      }
  } else {
      return 0
  }
}

//-> Fonction d'affichage du titre des bracelets qui n'ont pas encore de nom
export const clearLocalStorage = () => {
  let keys = [
    "token",
    "loggedIn",
    "trialLimit",
    "submitTimeLimit",
    "paylinetoken",
    "paylinestate",
    "paylinetoken-refund",
    "paylinestate-refund"
  ]

  for (let key of keys) {
    localStorage.removeItem(key)
  }
}

//-> Fonction d'affichage des intitulés de bracelet
export const checkText = (text, index) => {
  if (text !== "") {
      return parse(text)
  } else {
     if(index === undefined) {
        index = 0
     }
     
     if (index === 0) {
      return parse(i18n.t("Accounts:main-card-raw"))
     } else {
      return parse(i18n.t("Accounts:card") + " " + index)
     }
     
  }
}

//-> Fonction de remplacement des virgules par des points pour les opérations et les transactions
export const checkCardName = (card) => {
  
  if (card.texteclient !== undefined && card.texteclient !== "") {
      return card.texteclient
  } else {
      if (card.textepms !== undefined) {
          return card.textepms
      } else {
          return ""
      }
  }
}

export const replaceDot = (str) => {

  let newStr = str;

  if (str !== undefined && str !== null) {
      newStr = str.toString().replace(".", ",")
  }

  return newStr;
}