//-> Vue : Réinitialisation du mot de passe / 1er login

import React, { Component } from 'react';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import FormErrors from '../components/FormErrors';
import FormSuccess from '../components/FormSuccess';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { CheckPasswordStrength, handleUserInput, errorClass, onFieldKeyDown } from '../components/Helpers';
import { withTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import '../assets/css/general/connexion.css';

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password1: '',
      password1Shown: false,
      password2: '',
      password2Shown: false,
      formErrors: {password1: '',password2: ''},
      formSuccess: false,
      successMessage: '',
      pass1Valid: false,
      pass2Valid: false,
      token: '',
      formValid: false
    };

    this.togglePassword1 = this.togglePassword1.bind(this)
    this.togglePassword2 = this.togglePassword2.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }

  componentDidMount() {
    this.setState({
      token: this.props.match.params.token
    })
  }

  validateField(fieldName, value) {
    
    let pass1Valid = this.state.pass1Valid
    let pass2Valid = this.state.pass2Valid
    let fieldValidationErrors = this.state.formErrors

    const {t} = this.props

    switch(fieldName) {
     
      case 'password1':
        let result = CheckPasswordStrength(value)
        pass1Valid = result[1]
        fieldValidationErrors.password1 = (pass1Valid || value === "") ? '' : t("FormErrors:invalid-password")
        
        pass2Valid = value === this.state.password2 ? true : false
       
        fieldValidationErrors.password2 = (pass2Valid || value === "") ? '' : t("FormErrors:unidentical-password")

        fieldValidationErrors.extraText = (pass1Valid || value === "") ? '' : result[0]
      break;

      case 'password2':
        pass2Valid = value === this.state.password1 ? true : false
        fieldValidationErrors.password2 = (pass2Valid || value === "") ? '' : t("FormErrors:unidentical-password")
      break;

      case 'error':
        fieldValidationErrors.error = value
      break;

      default:
      break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      pass1Valid: pass1Valid,
      pass2Valid: pass2Valid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.pass1Valid && this.state.pass2Valid && this.state.token !== ""
    });
  }

  queryApi = async (token, mdp) =>  {

    const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
    const {t, history} = this.props;

    await fetch(REACT_APP_API_URL + "/changepassword", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-API-KEY': REACT_APP_API_KEY
      },
      body: JSON.stringify({
        "token": token,
        "mdp": mdp
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        if (result.reponse === "modifie") {
          this.setState({
            formSuccess: true,
            successMessage: t("FormSuccess:reset-success")
          })

          setTimeout(function() {
            history.push({
              pathname: "/login"
            })
          }, 2000)

        } else {
          
          let trialLimit = Number(localStorage.getItem('trialLimit'));

          if (trialLimit >= 2) {
            let submitTimeLimit = Number(localStorage.getItem('submitTimeLimit'));
            let currentDate = new Date();
            let minutesToAdd = 2;
            let futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);
          
            if (submitTimeLimit === 0) {
              localStorage.setItem('submitTimeLimit', futureDate.getTime());
            }
  
          } else {
            localStorage.setItem('trialLimit', trialLimit+1)
          }

          this.setState({
            formErrors: {
              error: result.reponsetexte
            }
          });

        }
      },
      (error) => {
        this.setState({
          formErrors: {
            error: error
          }
        });
      }
    )
  }

  togglePassword1 = (e) => {
    e.preventDefault()
    this.setState({
      password1Shown : !this.state.password1Shown
    })
  }

  togglePassword2 = (e) => {
    e.preventDefault()
    this.setState({
      password2Shown : !this.state.password2Shown
    })
  }

  onVerify = async () => {
    const { t } = this.props;
    const { executeRecaptcha } = this.props.googleReCaptchaProps;
    
    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }
    
    const token = await executeRecaptcha('ResetPassword');

    if(this.state.formValid && token !== null) {
      if (this.state.password1 !== "" && this.state.password2 !== "") {

        let submitTimeLimit = Number(localStorage.getItem('submitTimeLimit'));
        
        if (submitTimeLimit !== 0) {
          let currentDate = new Date();

          if (currentDate.getTime() > submitTimeLimit) {
            localStorage.removeItem('submitTimeLimit')
            localStorage.removeItem('trialLimit')
            this.queryApi(this.state.token, this.state.password2)
          } else {
            let endDate = new Date(submitTimeLimit);
            let minutesLeft = parseInt(Math.abs(endDate.getTime() - currentDate.getTime()) / (1000 * 60) % 60);

            this.setState({
              formErrors: {error:t("FormErrors:password-limit", {
                minutesLeft : minutesLeft+1
              })}
            })
          }
        } else {
          this.queryApi(this.state.token, this.state.password2)
        }

      } else {
        this.setState({
          formErrors: {error:t("FormErrors:empty-fields")}
        })
      }
    } else {
      this.setState({
        formErrors: {error:t("FormErrors:empty-fields")}
      })
    }

  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({
      formErrors: {},
      formSuccess: false
    })
    this.onVerify();
  }
  
  render() {
    
    const { location, i18n, t } = this.props;
    
    const isFirstLogin = () => {

      let result = false
      let route = "first-login";

      if (location.pathname.indexOf(route) > -1) {
        result = true
      }

      return result
    }

    return (
      <div className="connect">

        <HelmetProvider>
        {isFirstLogin() &&
            <Helmet htmlAttributes={{ lang : i18n.language }}>
              <title>{t('ResetPassword:title-alt')}</title>
              <meta name="description" content={t("ResetPassword:desc-alt")} />
            </Helmet>
        }
        {!isFirstLogin() &&
              <Helmet htmlAttributes={{ lang : i18n.language }}>
                <title>{t('ResetPassword:title')}</title>
                <meta name="description" content={t("ResetPassword:desc")} />
              </Helmet>
        }
        </HelmetProvider>

        
        <LanguageSwitcher />
        
        <form onSubmit={this.handleSubmit}>

          <Logo className='logo'/>
          
          <div className="form">
         
          {isFirstLogin() === true &&
                  <div className="intro">
                    <h2>{t('ResetPassword:login')}</h2>
                    <div className="app-title">{t('ResetPassword:app-title')}</div>
                    <p className="text-center cl-turquoise">{t('ResetPassword:first-login')}</p>
                  </div>
          }
          {isFirstLogin() === false &&
                  <div className="intro">
                    <div className="title">{t('ResetPassword:page-title')}</div>
                    <p className="text-center">{t('ResetPassword:page-subtitle')}</p>
                  </div>
          }

            <FormErrors formErrors={this.state.formErrors} />
            <FormSuccess formSuccess={this.state.formSuccess} successMessage={this.state.successMessage} />

            <div className={`form-group ${errorClass(this.state.formErrors.password1)}`}>
                <label htmlFor="password1">{t('ResetPassword:password1')}
                  <input id="password1" name="password1" type={this.state.password1Shown ? "text" : "password"} className="form-control" placeholder={t('ResetPassword:password1-enter')} onChange={
                    (e) => {
                      handleUserInput(e, this)
                    }
                  } onKeyDown={
                      (e) => {
                        onFieldKeyDown(e, this)
                      }
                    }
                  />
                  <button className={this.state.password1Shown ? "showHide on" : "showHide"} onClick={this.togglePassword1}></button>
                </label>
            </div>

            <div className={`form-group ${errorClass(this.state.formErrors.password2)}`}>
                <label htmlFor="password2">{t('ResetPassword:password2')}
                  <input id="password2" name="password2" type={this.state.password2Shown ? "text" : "password"} className="form-control" placeholder={t('ResetPassword:password2-enter')} onChange={
                      (e) => {
                        handleUserInput(e, this)
                      }
                    } onKeyDown={
                      (e) => {
                        onFieldKeyDown(e, this)
                      }
                    } />
                  <button className={this.state.password2Shown ? "showHide on" : "showHide"} onClick={this.togglePassword2}></button>
                </label>
            </div>

            <input type="hidden" name="token" id="token" value={this.props.match.params.token} />

          </div>
          <button type="submit" className="continue">{t('ResetPassword:continue')}</button>
        </form>
      </div>
    );
  }
}

export default withTranslation()(withGoogleReCaptcha(ResetPassword));
