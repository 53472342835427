//-> Affichage des opérations

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatFullDate, checkText, checkCardName, replaceDot } from "../../components/Helpers";
import parse from "html-react-parser";
import { useEffect, useState } from "react";

const Operations = (props) => {
    const { annexeid } = useParams();
    const { i18n, t } = useTranslation();
    const [panelTransition, setPanelTransition] = useState("fadeOut");
    const { language, hideAccounts, loaderState, cards, setEditModalIsOpenToTrue, setBlockModalIsOpenToTrue, editModalIsOpen, blockModalIsOpen } = props;
    let cardInfo = null
    let operations =  null
    let name = null
    let blocage = null
    let cloture = null
    let limitetype = null
    let limitemontant = null
    let solde = null
    
    const editCard = () => {
        setEditModalIsOpenToTrue()
    }

    const deleteCard = () => {
        setBlockModalIsOpenToTrue()
    }

    const getCardInfo = (codesannexes, annexeid) => {
        
        let getCardInfo = null
        let getCardIndex = null
        let tmpObj = null

        if (codesannexes !== undefined) {
            if (annexeid !== undefined) {
                for (let obj in codesannexes) {
                    if (Number(codesannexes[obj].annexeid) === Number(annexeid)) {
                        getCardIndex = Number(obj)
                        getCardInfo =  codesannexes[obj]
                        break
                    }
                }
            }
        }
        
        if (getCardIndex !== null && getCardInfo !== null) {
            tmpObj = {}
            tmpObj.index = getCardIndex
            tmpObj.info = getCardInfo
        }

        return tmpObj

    }

    

    if (cards !== null) {

        cardInfo = getCardInfo(cards.codesannexes, annexeid)
        operations = cards.mouvements
        cloture = cards.cloturele !== undefined && cards.cloturele !== ""

        if (cardInfo !== null) {

            name = checkText(checkCardName(cardInfo.info), (cardInfo.index+1))

            blocage = Number(cardInfo.info.blocageetat)
            
            if (cardInfo.info.limitemontant !== "undefined" && cardInfo.info.limitemontant !== undefined && String(cardInfo.info.limitemontant) !== "0") {
                limitemontant = t("Accounts:limited") + ": " + replaceDot(cardInfo.info.limitemontant) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")
            } else {
                limitemontant = ""
            }

           
            if(cardInfo.info.limitetype === "" || cardInfo.info.limitetype === "undefined" || cardInfo.info.limitetype === undefined) {
                limitetype = parse(t("Accounts:no-limit"))
            } else {
                limitetype = parse(t("Accounts:" + cardInfo.info.limitetype))
            }

          
            if (cardInfo.info.disponible !== undefined && cardInfo.info.disponible !== "undefined" && cardInfo.info.disponible !== null && String(cardInfo.info.disponible) !== "0") {
                solde = parse(t("Accounts:remains") + " " + replaceDot(cardInfo.info.disponible) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
            } else {
                if ((cardInfo.info.limitemontant === "" || cardInfo.info.limitemontant === undefined) || cardInfo.info.solde === "0") {
                    solde = ""
                } else {
                    if(cardInfo.info.disponible === null) {
                        if (cardInfo.info.limitetype === "") {
                            solde = parse(t("Accounts:no-card-limit"))
                        } else {
                            solde = parse(t("Accounts:remains") + " 0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                        }
                    } else {
                        solde = parse(t("Accounts:remains") + " " + replaceDot(cardInfo.info.disponible) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                    }
                }

            }

        } else {
            

            if (Number(annexeid) === 0) {
               
                name = checkText(checkCardName(cards))

                blocage = Number(cards.blocageetat)
                cloture = cards.cloturele !== undefined && cards.cloturele !== ""
                limitemontant = ""
                limitetype = ""

                if (cards.disponible !== undefined && cards.disponible !== "undefined" && String(cards.disponible) !== "0") {
                    solde = parse(t("Accounts:remains") + " " + replaceDot(cards.disponible) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                } else {
                    if(cards.disponible === null) {
                        solde = parse(t("Accounts:remains") + " 0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                    } else {
                        solde = parse(t("Accounts:no-card-limit"))
                    }
                }
            }
        }
       
    }

    useEffect(() => {
        if (loaderState === "off hidden" && (hideAccounts === "hide hidden" || document.body.classList.contains("desktop"))) {
            setTimeout(function() {
                setPanelTransition("fadeIn")
            }, 300)
        }

        if (i18n.language !== language) {
            window.location.reload()
        }

        return () => {
            setPanelTransition("fadeOut")
        }
    }, [language, loaderState, hideAccounts, cards, i18n.language]);

    return (
        <div className={`operations-container panel-container ${panelTransition}${((editModalIsOpen !== undefined && editModalIsOpen) || (blockModalIsOpen !== undefined && blockModalIsOpen)) ? " modal-open" : ""}`}>
            {cards !== null && 
            <>
            <div className="panel-title my-cards desktop-all bolder-x">{parse(t("Accounts:my-cards"))}</div>

            {(annexeid !== undefined || Number(annexeid) === 0) && 
                <div className="operations-header">
                    <div className="card-info desktop-all">
                        {(cardInfo !== null || Number(annexeid) === 0) &&
                            <>
                                {Number(annexeid) === 0 &&
                                    <div className="main-card bolder">{t('Accounts:main-card-raw')}</div>
                                }
                                <div className="name cl-turquoise bolder-x">{name}</div>
                            </>
                        }
                            {blocage === 1 &&
                                <div className="limit">
                                    <div className="blocked-card bolder">{parse(t("Accounts:blocked-card"))}</div>
                                    <div className="blocked-text normal">{parse(t("Accounts:unblock-reception"))}</div>
                                </div>
                            }

                        
                            {blocage === 0 &&
                                <div className="limit bold">

                                    {limitemontant !== "" && 
                                        <>
                                        {limitemontant} / {limitetype}
                                        <i className="normal"> - {solde}</i>
                                        </>
                                    }

                                    {limitemontant === "" && 
                                        <i className="normal">{solde}</i>
                                    }
                                    
                                </div>
                            }
                    </div>
                    {blocage === 0 && cloture === false &&
                    <div className="edit-box">
                        <button className="edit" onClick={editCard} value={annexeid}><span>{parse(t("Accounts:edit-card"))}</span></button>
                        <button className="block" onClick={deleteCard} value={annexeid}><span>{parse(t("Accounts:block-card"))}</span></button>
                    </div>
                    }
                </div>
            }
            
            <div className="recent-ops text-align-left cl-turquoise bolder-x">{parse(t("Accounts:recent-ops"))}</div>
            
            <div className={`operations table`}>
                {(operations.length > 0 && operations !== undefined) && 
                    <div className="table-heading">
                        <div className="table-row cl-grey-dark bold">
                            <div className="row-1">{parse(t("Accounts:date"))}</div>
                            <div className="row-2">{parse(t("Accounts:card"))}</div>
                            <div className="row-3">{parse(t("Accounts:location-type"))}</div>
                            <div className="row-4">{parse(t("Accounts:amount"))}</div>
                        </div>
                    </div>
                }
                <div className={`table-body${blocage === 1 ? " blocked" : ""}`}>
                {operations !== undefined && operations.length > 0 && operations.map((obj, i) => {
                    return((Number(obj.annexeid) === Number(annexeid) || annexeid === undefined) ?
                        
                        <div key={i} className="table-row operation">
                            <div className="row-1">{formatFullDate(obj.dateheure)}</div>

                            {(obj.annexeid === null || Number(obj.annexeid) === 0) && 
                                <div className="row-2 bracelet">{checkText(checkCardName(cards))}</div>
                            }

                            {obj.annexeid !== null && getCardInfo(cards.codesannexes, obj.annexeid) !== null &&
                                <div className="row-2 bracelet">{checkText(checkCardName(getCardInfo(cards.codesannexes, obj.annexeid).info), getCardInfo(cards.codesannexes, obj.annexeid).index+1)}</div>
                            }
                            
                            <div className="row-3">{obj.nomlieu !== "" ? obj.nomlieu + " : " : ""}{parse(obj.explication)}</div>
                            <div className="row-4 montant bolder-x">{replaceDot(obj.montant)}&nbsp;{(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")}</div>
                        </div>

                    : '')
                })}

                {(operations.length === 0 || operations === undefined) && 
                    <div className="table-body">
                        <div className="table-row operation">
                            <div>{parse(t("Accounts:no-operations"))}</div>
                        </div>
                    </div>
                }
                </div>
            </div>
            </>
            }
        </div>
    )
    
}

export default Operations