import parse from "html-react-parser";
import { formatFullDate, replaceDot } from "../../components/Helpers";
import { useTranslation } from "react-i18next";
import familypic from '../../assets/images/family.png';
import { useParams } from "react-router-dom";

//-> Affichage du solde du compte sur le tableau de bord
const AccountBalance = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { firstactive, data } = props;

    if (data !== null) {
        let newId = id

        if (newId === undefined) {
            newId = firstactive !== undefined ? firstactive : data.comptes[0].id
        } else {
            newId = id
        }
        
        let balance = ""
        let date = ""
        let cloture = ""
        let blocage = false;
        let devise = "";

        Object.keys(data.comptes).forEach((i) => {
            if (String(data.comptes[i].id) === String(newId)) {

                balance = replaceDot(data.comptes[i].solde);
                devise = data.comptes[i]?.monnaie !== "" ? data.comptes[i]?.monnaie.toUpperCase() : "€";
                cloture = data.comptes[i].cloturele !== undefined && data.comptes[i].cloturele !== "";
                if (data.comptes[i].blocageetat === "1") {
                    blocage = true;
                }
                date = formatFullDate(data.comptes[i].dernieresynchro);
            }
        });
        
        return(
            <div className={`account-balance ${(cloture || blocage) ? 'closed' : ''}`}>
                <div className="mobile-all left"><img src={familypic} alt={parse(t("Accounts:account-balance"))} width="154" height="210" /></div>
                <div className="right">
                    <div className="caption bold">{parse(t("Accounts:account-balance"))}</div>
                    <div className="data">{parse(t("Accounts:on-date"))} {date}</div>
                    {blocage &&
                    <div className="blocked text-center">{parse(t("Accounts:blocked-account"))}</div>
                    }
                    <div className="balance bolder-x uppercase">{balance} {devise}</div>
                </div>
                
            </div>
        )
    } else {
        return(
            null
        )
    }
   
}

export default AccountBalance