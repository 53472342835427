//-> Boîte de dialogue de blocage d'un bracelet

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isExpired } from "react-jwt";
import parse from 'html-react-parser';
import { t } from "i18next";
import '../../assets/css/elements/Dashboard/Modal.css';

const BlockModal = (props) => {
  const { id, annexeid } = useParams()
  const { cards } = props
  const [currentValue, setCurrentValue] = useState("")
  const [isMainCard, setIsMainCard] = useState(false)
  const [changeResult, setChangeResult] = useState("")

  useEffect(() => {

    if (cards !== undefined) {

      Object.keys(cards.codesannexes).forEach((i) => {
          if (annexeid !== undefined) {
              if (Number(cards.codesannexes[i].annexeid) === Number(annexeid)) {
                
                if (cards.codesannexes[i].texteclient === "") {

                  if (cards.codesannexes[i].textepms === "") {
                    setCurrentValue(parse(t("Accounts:card") + " " + (Number(i)+1)))
                  } else {
                    setCurrentValue(parse(cards.codesannexes[i].textepms))
                  }
                  
                } else {
                  setCurrentValue(parse(cards.codesannexes[i].texteclient))
                }

                
              }
              
          }
          
      })

      if (Number(annexeid)===0) {
        if (cards.texteclient === "") {
          setCurrentValue(parse(t("Accounts:main-card-raw")))
        } else {
          setCurrentValue(parse(cards.texteclient))
        }
        
        setIsMainCard(true)
      }
    }

  }, [annexeid, cards])

  const blockCard = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    if (!isExpired(token.data)) {

        const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
        let url = ""

        if (Number(annexeid) === 0) {
          url = id
        } else {
          url = id + "/" + annexeid
        }

        await fetch(REACT_APP_API_URL + "/blocage/" + url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept' : '*/*',
                'X-API-KEY': REACT_APP_API_KEY,
                "Authorization" : "Bearer " + token.data
            }
        }).then(function(response){
            return response.json();
        }).then(
            (result) => {
                if (result.reponse === "ok") {

                    if (cards !== null) {

                      if (Number(annexeid) === 0) {
                        cards.blocageetat = result.data.blocageetat
                        setChangeResult(parse(t("BlockModal:card-blocked")))
                          
                        setTimeout(function() {
                          props.closeModal()
                        }, 800);
                        
                      } else {


                        Object.keys(cards.codesannexes).forEach((i) => {
                            if (annexeid !== undefined) {
                                if (Number(cards.codesannexes[i].annexeid) === Number(annexeid)) {
                                  cards.codesannexes[i].blocageetat = result.data.blocageetat
                                }
                            }
                            
                        })

                        props.setCards(cards);

                        

                        setChangeResult(parse(t("BlockModal:card-blocked")))
                        
                        setTimeout(function() {
                          props.closeModal()
                        }, 800);
                      
                      }
                    } else {
                      setChangeResult(parse(t("BlockModal:error-cards")))
                    }
                }
            },
            (error) => {
                setChangeResult(parse(error))
                return error
            }
        )
        
        
    } else {
        // Error
    }
  }

    const handleSubmit = (e) => {
        e.preventDefault()
        blockCard()
    }

    
      return (
        <div className="modal-inner relative">
          <div className="modal-title bolder-x">{parse(t("Accounts:block-card"))}</div>
          <div className="message">{parse(t("BlockModal:block-card-confirm"))}</div>
          <div className="current-name bolder-x cl-turquoise">{parse(currentValue)}</div>

          {isMainCard &&
            <>
            <div className="main-card bolder">{parse(t("BlockModal:main-card"))}</div>
            <div className="warning cl-grey-dark">{parse(t("BlockModal:main-card-warning"))}</div>
            </>
          }

          {!isMainCard && 
            <div className="warning cl-grey-dark">{parse(t("BlockModal:card-warning"))}</div>
          }


          <form onSubmit={handleSubmit}>

            <div className="buttons relative flex flex-direction-row align-items-center justify-content-evenly">
              <button type="submit" onClick={handleSubmit} className="modal-button action">{parse(t("BlockModal:block"))}</button>
              <button type="button" onClick={props.closeModal} className="modal-button cancel" >{parse(t("BlockModal:cancel"))}</button>
            </div>
            
            {changeResult !== "" && changeResult !== "error-cards" && changeResult !== "error-submit" &&
              <div className='form-success'>
                <p className="bg-success2 text-center"><span className='valid'></span>{parse(changeResult)}</p>
              </div>
            }


            {changeResult !== "" && (changeResult === "error-cards" || changeResult === "error-submit") && 
              <div className='form-errors'>
                <p className="bg-error2 text-center">
                  <strong>{parse(t("FormErrors:error").toUpperCase())}</strong>&nbsp;
                  {parse(t("BlockModal:" + changeResult))}
                </p>
              </div>
            }

          </form>
        </div>
      );
  }
  
export default BlockModal