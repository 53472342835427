//-> Page de remboursement

import { useState, useEffect } from "react";
import { isExpired } from "react-jwt";
import { formatFullDate, replaceDot } from "../../components/Helpers";
import parse from "html-react-parser";
import IBAN from "iban";
import BIC from "bic"
import { useTranslation } from "react-i18next";
import loader from '../../assets/images/loader.svg';

const Refund = (props) => {
    const { i18n, t } = useTranslation()
    const { cards, loaderState, hideAccounts, data, setData } = props;
    const [message, setMessage] = useState("");
    const [iban, setIban] = useState("");
    const [bic, setBic] = useState("");
    const [requestInitiated, setRequestInitiated] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [panelTransition, setPanelTransition] = useState("fadeOut");
    const [refunds, setRefunds] = useState(null);
    const [formItemsState, setFormItemsState] = useState(null);        
    const [error, setError] = useState({state:false,message:null,messageId:""});
    const [directRefundLoaderState, setDirectRefundLoaderState] = useState("");
    const [directRefundInitiated, setDirectRefundInitiated] = useState(false);
    const [directRefundStatus, setDirectRefundStatus] = useState({
        state:false,
        maxremboursable:0,
        montant:0,
        montantfrais:0,
        solde:0
    });

    const sendRefundLiveStatus = async() => {
        const token = JSON.parse(localStorage.getItem("token"));
        
        if (!isExpired(token.data)) {

            const paylinetoken = localStorage.getItem("paylinetoken-refund");

            if (paylinetoken !== null) {

                const { REACT_APP_API_DIRECT_REFUND_URL, REACT_APP_API_KEY } = process.env;
    
                await fetch(REACT_APP_API_DIRECT_REFUND_URL + "/tempaccept/" + paylinetoken, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    }
                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {
                        if (result.reponse !== "ok") {
                            setError(prevState => ({
                                ...prevState,
                                state:true,
                                message:parse(t("Refund:update-failure"))
                            }));
                        }
                        
                    },
                    (error) => {
                        setError(prevState => ({
                            ...prevState,
                            state:true,
                            message:parse(error)
                        }));
                    }
                );
            }
        
        } else {
            // Error
        }
    }

    const sendDirectRefund = async () => {
        const token = JSON.parse(localStorage.getItem("token"));
        
        if (!isExpired(token.data) && cards.id !== null) {
            
            if (!directRefundInitiated) {
                localStorage.removeItem("paylinetoken-refund");
                localStorage.removeItem("paylinestate-refund");
                
                setDirectRefundInitiated(true);

                const { REACT_APP_API_DIRECT_REFUND_URL, REACT_APP_API_KEY } = process.env;
        
                await fetch(REACT_APP_API_DIRECT_REFUND_URL + "/ini/" + cards.id, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    },
                    body : JSON.stringify({ 
                        "montant" : directRefundStatus.montant,
                        "returnurl": document.location.href,
                        "cancelurl": document.location.href
                    })

                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {
                        if (result.reponse === "Created") {
                            
                            if (window.Payline.Api !== undefined) {
                                let texte= JSON.stringify(result);
                                let t1 = JSON.parse(texte);
                                //let t2 = t1.data.redirectURL;
                                let t3 = t1.data.token;
                                localStorage.setItem("paylinetoken-refund", t3);
                                window.Payline.Api.init();  
                                window.Payline.Api.reset(t3,null);
                                window.Payline.Api.show();
                                let tmpAccounts = [];

                                Object.keys(data.comptes).forEach((i) => {
                                    let tmpAccount = data.comptes[i];
                                    if (String(data.comptes[i].id) === String(cards.id)) {
                                        // tmpAccount.blocageetat = "1";
                                        tmpAccount.remdirectencours = true;
                                        tmpAccount.remdirectremboursable = false;
                                    }

                                    tmpAccounts.push(tmpAccount);
                                    
                                });
                                
                                setData(prevState => ({
                                    ...prevState,
                                    comptes: tmpAccounts
                                }));

                               

                            } else {
                                setDirectRefundInitiated(false)
                                setError(prevState => ({
                                    ...prevState,
                                    state:true,
                                    message:parse(t("Refund:api-failure"))
                                }));
                            }
                            
                        } else {
                            setDirectRefundInitiated(false)
                            setError(prevState => ({
                                ...prevState,
                                state:true,
                                message:parse(t("Refund:refund-failure"))
                            }));
                        }
                        
                    },
                    (error) => {
                        setError(prevState => ({
                            ...prevState,
                            state:true,
                            message:parse(error)
                        }));
                    }
                )
            }
                
        } else {
            // Error
        }
    };

    useEffect(() => {
        
        const getDirectRefundStatus = async(id) => {
            const token = JSON.parse(localStorage.getItem("token"));

            if (!requestInitiated) {
                setRequestInitiated(true);

                if (token !== null && !isExpired(token.data)) {
        
                    const { REACT_APP_API_DIRECT_REFUND_URL, REACT_APP_API_KEY } = process.env;
                
                    await fetch(REACT_APP_API_DIRECT_REFUND_URL + "/situationreelle/" + id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Accept' : '*/*',
                            'Accept-Language'  : i18n.language,
                            'Content-Language'  : i18n.language,
                            'X-API-KEY': REACT_APP_API_KEY,
                            "Authorization" : "Bearer " + token.data
                        }
                    })
                    .then(function(response){
                        return response.json();
                    })
                    .then(
                        (result) => {
                            // if (isActive) {
                                setRequestInitiated(false);
                                
                                if (directRefundLoaderState === "") {
                                    setDirectRefundLoaderState("off hidden");
                                }

                                if (result.reponse === "ok") {
                                    
                                    setDirectRefundStatus(prevState => ({
                                        ...prevState,
                                        state:true,
                                        maxremboursable:result.reponsetexte.maxremnboursable,
                                        montant:Number(result.reponsetexte.montant),
                                        montantfrais:result.reponsetexte.montantfrais,
                                        solde:result.reponsetexte.solde
                                    }));
                                }

                                if (result.reponse === "Not Found" || result.reponse === "Forbidden" || result.reponse === "Service Unvailable") {
                                    setDirectRefundStatus(prevState => ({
                                        ...prevState,
                                        state:true
                                    }));

                                    setError(prevState => ({
                                        ...prevState,
                                        state:true,
                                        message:parse(t("Refund:unavailable")),
                                        messageId:null
                                    }));
                                    
                                }
            
                                if (result.reponse.toLowerCase() === "erreur") {
                                    
                                    setDirectRefundStatus(prevState => ({
                                        ...prevState,
                                        state:true
                                    }));

                                    setError(prevState => ({
                                        ...prevState,
                                        state:true,
                                        message:parse(result.reponse),
                                        messageId:null
                                    }));
                                }
                            // }
                        },
                        (error) => {
                            setError(prevState => ({
                                ...prevState,
                                state:true,
                                message:parse(error),
                                messageId:null
                            }));
                        }
                    )

                    
        
                } else {
                    setError(prevState => ({
                        ...prevState,
                        state:true,
                        message:parse(t("Refund:token-issue")),
                        messageId:null
                    }));
                }
            }
        };

        if (loaderState === "off hidden" && (hideAccounts === "hide hidden" || document.body.classList.contains("desktop"))) {
            setTimeout(function() {
                setPanelTransition("fadeIn")
            }, 150)
        }

        if (cards?.avecrembourdirect !== 1) {
            if (cards !== null) {
                getRefundsStatus(cards.id);
            }
        }
        
        if (cards?.avecrembourdirect === 1) {

            if (localStorage.getItem("paylinestate-refund") === null) {
                if (!cards.remdirectencours && cards.remdirectremboursable) {
                    getDirectRefundStatus(cards.id);
                }
            } else {
                
                if (localStorage.getItem("paylinestate-refund") === "PAYMENT_SUCCESS") {
                    sendRefundLiveStatus();
                } else {
                    if (localStorage.getItem("paylinestate-refund") === "PAYMENT_FAILURE") {
                        
                        setDirectRefundLoaderState("off hidden");
    
                        setError(prevState => ({
                            ...prevState,
                            state:true,
                            message:parse(t("Refund:payment-not-processed")),
                            messageId:null
                        }));

                    }
    
                    
                }
            }

           

            if (cards.remdirectencours && !cards.remdirectremboursable) {
                
                setDirectRefundLoaderState("off hidden");

                setError(prevState => ({
                    ...prevState,
                    state:true,
                    message:parse(t("Refund:payment-in-progress")),
                    messageId:"payment-in-progress"
                }));
            }

            if (!cards.remdirectencours && cards.remdirectremboursable) {
                setDirectRefundLoaderState("off hidden");

            }

            if (!cards.remdirectencours && !cards.remdirectremboursable) {
                setDirectRefundLoaderState("off hidden");
                setError(prevState => ({
                    ...prevState,
                    state:true,
                    message:parse(t("Refund:ceiling-exceeded")),
                    messageId:null
                }));
            }

        }
        
        return () => {
            setPanelTransition("fadeOut")
        }

    // eslint-disable-next-line
    }, [cards]);

    const askRefund = async (id) => {
        const token = JSON.parse(localStorage.getItem("token"))

        if (!isExpired(token.data)) {
            
            if (!requestInitiated) {

                setRequestInitiated(true)
            
                const { REACT_APP_API_REFUND_URL, REACT_APP_API_KEY } = process.env;
        
                await fetch(REACT_APP_API_REFUND_URL + "/demande/" + id, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    },
                    body : JSON.stringify({ 
                        "montant" : 1,
                        "message": message,
                        "iban": iban,
                        "bic": bic
                    })

                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {
                        if (result.reponse === "ok") {
                            getRefundsStatus(id)
                            setRequestInitiated(false)
                            setRequestStatus(true)
                        } else {
                            setRequestInitiated(false)
                            setRequestStatus(false)
                        }

                    },
                    (error) => {
                        setRequestStatus(false)
                        return error
                    }
                )
            }
                
        } else {
            setRequestStatus(false)
        }
    }

    const getRefundsStatus = async (id) => {
        const token = JSON.parse(localStorage.getItem("token"));
        
        if (!isExpired(token.data)) {
            
            if (!requestInitiated) {

                setRequestInitiated(true)
            
                const { REACT_APP_API_REFUND_URL, REACT_APP_API_KEY } = process.env;
        
                await fetch(REACT_APP_API_REFUND_URL + "/demande/" + id, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    }
                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {
                        if (result.reponse === "ok") {
                            setRefunds(result.data.demanderemboursement)
                            setRequestInitiated(false)
                        } else {
                            setRequestInitiated(false)
                        }

                    },
                    (error) => {
                        return error
                    }
                )
            }
                
        } else {
            setRequestStatus(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (message !== "" && iban !== "" && bic !== "") {
            if(IBAN.isValid(iban) && BIC.isValid(bic)) {
                askRefund(cards.id, message, iban, bic)
                setFormItemsState(null)
            } else {
                setFormItemsState(false)
            }

        } else {
            alert(t("Refund:enter-details"))
        }
    }

    const onEnterPress = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e)
        }
    }
    
    const stateInfo = (state) => {

        let newInfo = {};

        if (state === "En attente") {
            newInfo.text = parse(t("Refund:en-attente"));
            newInfo.class = "en-attente";
        }

        if (state === "Acceptée") {
            newInfo.text = parse(t("Refund:acceptee"));
            newInfo.class = "acceptee";
        }

        if (state === "Refusée") {
            newInfo.text = parse(t("Refund:refusee"));
            newInfo.class = "refusee";
        }

        return newInfo;
    }

    if (cards !== null) {
        
        if(Number(cards.avecrembourdirect) === 1) {
            return (
                <>
                <div className={`refund-container panel-container flex flex-direction-column justify-content-start align-items-stretch relative ${panelTransition}`}>


                    <div className="panel-title refund desktop-all bolder-x relative">{parse(t("Refund:refund"))}</div>
                    
                    <div className="refund-box relative full-height flex flex-direction-column align-items-center justify-content-center">

                        <div className={`loader ${directRefundLoaderState}`}>
                            <img src={loader} alt={t("Account:loading")} width="100" height="100" />
                        </div>
            
                        <div className="content flex flex-direction-column justify-content-center align-items-center">
                            <div className="box bg-grey-light flex flex-direction-column justify-content-center align-items-center text-center">

                                {localStorage.getItem("paylinestate-refund") !== "PAYMENT_SUCCESS" && localStorage.getItem("paylinestate-refund") !== "PAYMENT_FAILURE" && error.state && error.message !== null && 
                                <>
                                    <p className="title">
                                        {parse(t("Refund:direct-refund-title"))}
                                    </p>

                                    <div className="message">{error.message}</div>
                                    
                                </>
                                }

                                {!cards?.remdirectencours && cards?.remdirectremboursable && localStorage.getItem("paylinestate-refund") === null && !error.state && error.message === null && 
                                    <>
                                    <p className="title">
                                        {parse(t("Refund:direct-refund-title"))}
                                    </p>

                                    <p className="max-refundable">
                                        {parse(t("Refund:max-refundable", {number:replaceDot(directRefundStatus.maxremboursable), devise:(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")}))}
                                    </p>

                                    <p className="refundable">
                                        {parse(t("Refund:refundable", {number:replaceDot(directRefundStatus.montant),devise:(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")}))}
                                    </p>

                                    <p className="fees">
                                        {parse(t("Refund:fees", {number:replaceDot(directRefundStatus.montantfrais), devise:(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")}))}
                                    </p>
                                    </>
                                }
                               
                                {localStorage.getItem("paylinestate-refund") === "PAYMENT_SUCCESS" &&
                                <>
                                    <div className="message-title cl-success1 bolder-x">{parse(t("Refund:direct-refund-success-title", {number:replaceDot(directRefundStatus.montant), devise:(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")}))}</div>
                                    <div className="message-text cl-success1">{parse(t("Refund:direct-refund-success-text"))}</div>
                                </>
                                }
                            
                                {localStorage.getItem("paylinestate-refund") === "PAYMENT_FAILURE" &&
                                <>
                                    <div className="warning"></div>
                                    <div className="message-title cl-error1 bolder-x">{parse(t("Refund:direct-refund-failure-title"))}</div>
                                    <div className="message-text cl-error1">{parse(t("Refund:direct-refund-failure-text"))}</div>
                                </>
                                }

                            </div>
                            {!cards?.remdirectencours && cards?.remdirectremboursable && error.state === false && error.message === null && 
                                <button className="direct-refund continue" onClick={(e)=>{
                                    sendDirectRefund();
                                }}>{parse(t("Refund:direct-refund"))}</button>
                            }

                            {(localStorage.getItem("paylinestate-refund") !== "PAYMENT_SUCCESS" && localStorage.getItem("paylinestate-refund") !== "PAYMENT_FAILURE" && error.state && error.message !== null) || (directRefundStatus.montant == 0 || directRefundStatus.montant == "0") && 
                            <button className="direct-refund continue disabled">{parse(t("Refund:direct-refund"))}</button>
                            }
                        </div>
                    </div>
                </div>
                </>
            )
        } else {
            if(Number(cards.demanderemboursement) === 1) {
                return (
                    <>
                        <div className={`refund-container panel-container flex flex-direction-column justify-content-start align-items-stretch relative ${panelTransition}`}>

                            <div className="panel-title refund desktop-all bolder-x relative">{parse(t("Refund:refund"))}</div>

                            {Number(cards.solde) !== 0 && requestStatus !== true && 
                                <>
                                    <div className="block-title bolder relative">{t("Refund:block-title")}</div>

                                    <div className="refund-wrapper">
                                        <form onSubmit={handleSubmit}>
                                            <div className="block bg-grey-light">

                                                <div className="form-group">

                                                    <label className="iban" htmlFor="iban">{t("Refund:iban")}
                                                        <input type="text" maxLength={120} name="iban" id="iban" className="form-control" onKeyDown={onEnterPress} onChange={e => {
                                                            setIban(e.target.value)
                                                        }} /> 
                                                    </label>

                                                    <label className="bic" htmlFor="bic">{t("Refund:bic")}
                                                        <input type="text" maxLength={120} name="bic" id="bic" className="form-control" onKeyDown={onEnterPress} onChange={e => {
                                                            setBic(e.target.value)
                                                        }} /> 
                                                    </label>

                                                    
                                                    <label className="message" htmlFor="message">{t("Refund:reason")}
                                                        <textarea maxLength={120} name="message" id="message" className="form-control" onKeyDown={onEnterPress} onChange={e => {
                                                            setMessage(e.target.value)
                                                        }}></textarea> 
                                                    </label>

                                                </div>

                                                <div className="text-center full-width">
                                                    <button type="submit" className="send-request">{t("Refund:send-request")}</button>
                                                </div>
                                            </div>

                                            {requestStatus !== null && requestStatus &&
                                                <div className='form-success'>
                                                    <p className="bg-success2 text-center"><span className='valid'></span>{parse(t("Refund:request-successful"))}</p>
                                                </div>
                                            }

                                            {requestStatus !== null && !requestStatus &&
                                            <div className='form-errors'>
                                                <p className="bg-error2 text-center">
                                                <strong>{parse(t("FormErrors:error").toUpperCase())}</strong>&nbsp;
                                                {parse(t("Refund:request-unavailable"))}
                                                </p>
                                            </div>
                                            }

                                            {formItemsState !== null && !formItemsState &&
                                            <div className='form-errors'>
                                                <p className="bg-error2 text-center">
                                                <strong>{parse(t("FormErrors:error").toUpperCase())}</strong>&nbsp;
                                                {parse(t("Refund:iban-bic-check"))}
                                                </p>
                                            </div>
                                            }
                                        </form>

                                    </div>
                                </>
                            }

                            <h3 className="cl-turquoise bolder-x">{parse(t("Refund:history"))}</h3>
                            
                            <div className="refunds table">

                                {(refunds !== undefined && refunds !== null && refunds.length > 0) && 
                                <div className="table-heading">
                                    <div className="table-row cl-grey-dark bold">
                                        <div className="row-1 state">{parse(t("Refund:state"))}</div>
                                        <div className="row-2 date-request">{parse(t("Refund:date-request"))}</div>
                                        <div className="row-3 message">{parse(t("Refund:message"))}</div>
                                        <div className="row-4 response">{parse(t("Refund:response"))}</div>
                                    </div>
                                </div>
                                }

                                <div className="table-body">
                                {refunds !== undefined && refunds !== null && refunds.length > 0 && refunds.map((obj, i) => {
                                    return(
                                        <div key={i} className="table-row refund">
                                            <div className={`row-1 state ${stateInfo(obj.situation).class}`}><span>{stateInfo(obj.situation).text}</span></div>
                                            <div className="row-2 date-request">{formatFullDate(obj.datedemande)}</div>
                                            <div className="row-3 message">{parse(obj.messageinitial)}</div>
                                            <div className="row-4 response">{parse(obj.messagereponse)}</div>
                                        </div>
                                    )
                                })}

                                {(refunds === undefined || refunds === null || refunds.length === 0) && 
                                    <div className="table-body">
                                        {parse(t("Refund:no-refunds"))}
                                    </div>

                                }

                                </div>
                            </div>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                    <div className={`refund-container panel-container flex flex-direction-column justify-content-start align-items-stretch relative ${panelTransition}`}>

                        <div className="panel-title refund desktop-all bolder-x relative">{parse(t("Refund:refund"))}</div>
                        {(Number(cards.solde) === 0 || requestStatus) && 
                            <div className="block bg-grey-light">
                                <div className="title">{parse(t("Refund:ask-reception"))}</div>
                            </div>
                        }

                        <h3 className="cl-turquoise bolder-x">{parse(t("Refund:history"))}</h3>
                                
                        <div className="refunds table">

                            {(refunds !== undefined && refunds !== null && refunds.length > 0) && 
                            <div className="table-heading">
                                <div className="table-row cl-grey-dark bold">
                                    <div className="row-1 state">{parse(t("Refund:state"))}</div>
                                    <div className="row-2 date-request">{parse(t("Refund:date-request"))}</div>
                                    <div className="row-3 message">{parse(t("Refund:message"))}</div>
                                    <div className="row-4 response">{parse(t("Refund:response"))}</div>
                                </div>
                            </div>
                            }

                            <div className="table-body">
                            {refunds !== undefined && refunds !== null && refunds.length > 0 && refunds.map((obj, i) => {
                                return(
                                    <div key={i} className="table-row refund">
                                        <div className={`row-1 state ${stateInfo(obj.situation).class}`}><span>{stateInfo(obj.situation).text}</span></div>
                                        <div className="row-2 date-request">{formatFullDate(obj.datedemande)}</div>
                                        <div className="row-3 message">{parse(obj.messageinitial)}</div>
                                        <div className="row-4 response">{parse(obj.messagereponse)}</div>
                                    </div>
                                )
                            })}

                            {(refunds === undefined || refunds === null || refunds.length === 0) && 
                                <div className="table-body">
                                    {parse(t("Refund:no-refunds"))}
                                </div>

                            }
                            </div>
                        </div>
                    </div>
                    </>
                )
            }
        }

    } else {
        return(null);
    }
}
    
export default Refund