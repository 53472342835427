//-> Item individuel de menu

import { Switch, Route, useParams, NavLink } from "react-router-dom";
import { getAccountById, toggleRecaptcha, clearLocalStorage } from "../../components/Helpers";
import parse from "html-react-parser"
import { useTranslation } from "react-i18next";

const Menu = (props) => {
    const { id } = useParams()
    const { t } = useTranslation()
    const { data, firstactive, className, location, readCard, history } = props;
    
    let newId = id
    if (newId === undefined) {
        if (data?.comptes.length > 0 || firstactive !== undefined) {
            newId = firstactive !== undefined ? firstactive : data?.comptes[0].id
        } else {
            toggleRecaptcha(true);
            clearLocalStorage(); 
            history.push({
                pathname: "/login"
            });
        }
    } else {
        newId = id
    }
    const currentAccount = getAccountById(data?.comptes, newId);

    return(
        <div className={`menu${className !== undefined ? ' ' + className : ''}`}>
            <div className="menu-item item1">

            <Switch location={location}>

                <Route path={`/dashboard/${newId}/theft-loss`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/dashboard/${newId}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>

                <Route path={`/dashboard/${newId}/reload`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/dashboard/${newId}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>
                <Route path={`/dashboard/${newId}/refund`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/dashboard/${newId}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>
                <Route path={`/dashboard/${newId}/contact`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/dashboard/${newId}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>

                <Route exact path={`/dashboard/${newId}/:id`}>
                    <NavLink onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/dashboard/${newId}`} activeClassName="active"><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>
                
                <Route exact path={`/dashboard/${newId}`}>
                    <NavLink onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/dashboard/${newId}`} activeClassName="active"><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>

                <Route exact strict path={`/dashboard`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold active`} to={`/dashboard/${newId}`} ><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>
            </Switch>

            </div>
            <div className="menu-item item2">
                <NavLink onClick={e => {
                    if (currentAccount?.cloturele !== undefined && currentAccount?.cloturele !== '') {
                        e.preventDefault()
                    } else {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}/theft-loss`;
                        }
                    }
                }} activeClassName="active" className={`${currentAccount?.cloturele !== undefined && currentAccount?.cloturele !== '' ? "disabled" : ""}`} to={`/dashboard/${newId}/theft-loss`}><i>&nbsp;</i><span>{parse(t("Menu:menu2"))}</span></NavLink>
            </div>
            <div className="menu-item item3">
                <NavLink onClick={(e) => {
                    if (Number(currentAccount?.avecrechargement) === 0 || Number(currentAccount?.blocageetat) === 1 || (currentAccount?.cloturele !== undefined && currentAccount?.cloturele !== "")) {
                        e.preventDefault()
                    } else {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}/reload`;
                        }
                    }
                }} activeClassName="active" className={`${(Number(currentAccount?.avecrechargement) === 0 || Number(currentAccount?.blocageetat) === 1 || (currentAccount?.cloturele !== undefined && currentAccount?.cloturele !== "")) ? "disabled" : ""}`} to={`/dashboard/${newId}/reload`}><i>&nbsp;</i><span>{parse(t("Menu:menu3"))}</span></NavLink>
            </div>
            <div className="menu-item item4">
                <NavLink activeClassName="active" to={`/dashboard/${newId}/refund`} onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}/refund`;
                        }
                    }}><i>&nbsp;</i><span>{parse(t("Menu:menu4"))}</span></NavLink>
            </div>
            <div className="menu-item item5">
                <NavLink activeClassName="active" className="" to={`/dashboard/${newId}/contact`} onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/dashboard/${newId}/contact`;
                        }
                    }}><i>&nbsp;</i><span>{parse(t("Menu:menu5"))}</span></NavLink>
            </div>
        </div>
    )
}

export default Menu