//-> Barre supérieure version mobile (langues / déconnexion / infos utilisateur)

import { useParams, NavLink } from "react-router-dom";
import parse from "html-react-parser"
import { formatDate, toggleDropDown, askNewPassword, clearLocalStorage } from "../../components/Helpers";
import { useTranslation } from "react-i18next";

const AccountShortInfoMobile = (props) => {
    const { id } = useParams()
    const { t } = useTranslation()
    const { data, firstactive, history } = props;
    
    let name
    let active
    let datecloture
    let etablissement

    if (data !== null) {
        let newId = id

        if (newId === undefined) {
            newId = firstactive !== undefined ? firstactive : data.comptes[0].id
        } else {
            newId = id
        }
        

        Object.keys(data.comptes).forEach((i) => {
            if (String(data.comptes[i].id) === String(newId)) {
                name = data.comptes[i].nomprenom
                etablissement = data.comptes[i].etablissement
                active = data.comptes[i].cloturele === "" ? true : false
                datecloture = !active ? parse(t("Accounts:closed-on")) + " " + formatDate(data.comptes[i].cloturele) : ""
            }
        });
        
    }

    return (
        <>
        {data !== null && 
        <div className={`header absolute mobile-all flex flex-direction-row justify-content-between${!active ? " bg-grey-light blocked" : " bg-white"}`}>
            <NavLink className="back-button bg-yellow relative" to="/dashboard"></NavLink>

            <div className={`short-info text-center flex flex-direction-column align-items-center justify-content-center relative`}>
                <div className="estab-name bolder-x">{etablissement}</div>
                <div className="account-name">{name}</div>
            </div>

            <ul className='dropdown-container user-menu user-menu-p2 box-shadow relative'>
            {data !== null &&
                <li className="current" onClick={(e) => {
                    toggleDropDown(".user-menu-p2")
                }}>
                    <ul className="dropdown">
                        <li className="cl-grey-dark">{data.nomprenom}</li>
                        <li>
                            <NavLink to="/password-reset" onClick={(e) => {
                                e.preventDefault()
                                askNewPassword()
                                toggleDropDown(".user-menu-p2")
                            }}>{parse(t("Accounts:password-change"))}</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={(e)=> {
                                e.preventDefault()
                                clearLocalStorage()
                                history.push({
                                    pathname:"/login"
                                })
                            }} to="/login">{parse(t("Accounts:logout"))}</NavLink>
                        </li>
                   
                    </ul>
                </li>
            }
                
                
            </ul>
        </div>

        
        }

        {data !== null && !active &&
            <div className="closed-account mobile-all flex align-items-center justify-content-center bg-grey-light">
                <span className="cl-black bolder-x uppercase">{parse(t("Accounts:closed-account"))}<br/>
                <i className="cl-black normal text-transform-none">{parse(t("Accounts:closed-on"))} {datecloture}</i></span>
            </div>
        }

       
        </>
    )
}

export default AccountShortInfoMobile