//-> Vue : Tableau de bord

import parse from 'html-react-parser';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { withTranslation, useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import Accounts from './Dashboard/Accounts';
import '../assets/css/general/connexion.css';
import '../assets/css/elements/Dashboard.css';

const Dashboard = (props) => {
  const { location } = useLocation()
  const { i18n, t } = useTranslation();
  const { id } = props;

    return (
      <div className="dashboard">

        <HelmetProvider>
          <Helmet htmlAttributes={{ lang : i18n.language }}>
          <title>{parse(t('Dashboard:title'))}</title>
          <meta name="description" content={parse(t("Dashboard:desc"))} />
          </Helmet>
        </HelmetProvider>
        
        <div className="wrapper">
          
          <Switch location={location}>
            <Route path={`/dashboard/:id`}>
              <Accounts id={id} {...props } />
            </Route>

            <Route exact strict path={`/dashboard`}>
              <Accounts {...props } />
            </Route>
          </Switch>

              

        </div>
          
      </div>
      );
  }

export default withTranslation()(Dashboard);
