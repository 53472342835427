//-> Vue : Page Login

import React, { Component } from 'react';
import FormErrors from '../components/FormErrors';
import FormSuccess from '../components/FormSuccess';
import parse from 'html-react-parser';
import { isExpired, decodeToken } from "react-jwt";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { handleUserInput, errorClass, onFieldKeyDown } from '../components/Helpers';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import bgConnectPages from '../assets/images/personnage.png';
import '../assets/css/general/connexion.css';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordShown: false,
      formErrors: {email: '', password: '', error: ''},
      emailValid: false,
      passValid: false,
      formValid: false
    }

    this.togglePassword = this.togglePassword.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }

  onVerify = async () => {
    const { t } = this.props;
    const { executeRecaptcha } = this.props.googleReCaptchaProps;
    
    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }

    const token = await executeRecaptcha('Login');

    if(this.state.formValid && token !== null) {

      if (this.state.email !== "" && this.state.password !== "") {

        let submitTimeLimit = Number(localStorage.getItem('submitTimeLimit'));
        
        if (submitTimeLimit !== 0) {
          let currentDate = new Date();

          if (currentDate.getTime() > submitTimeLimit) {
            localStorage.removeItem('submitTimeLimit')
            localStorage.removeItem('trialLimit')
            this.queryApi(this.state.email, this.state.password)
          } else {
            let endDate = new Date(submitTimeLimit);
            let minutesLeft = parseInt(Math.abs(endDate.getTime() - currentDate.getTime()) / (1000 * 60) % 60);

            this.setState({
              formErrors: {error:t("FormErrors:login-limit", {
                minutesLeft : minutesLeft+1
              })}
            })
          }
        } else {
          this.queryApi(this.state.email, this.state.password)
        }

      } else {
        this.setState({
          formErrors: {error: t("FormErrors:empty-fields")}
        });
      }
    } else {
      this.setState({
        formErrors: {error: t("FormErrors:empty-fields")}
      });
    }

  }

  validateField(fieldName, value) {
    
    const {t} = this.props;
    let emailValid = this.state.emailValid;
    let passValid = this.state.passValid;
    let fieldValidationErrors = this.state.formErrors;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = (emailValid || value === "") ? '' : t('FormErrors:invalid-email');
      break;
      case 'password':
        passValid = value !== "" && value.length >= 10
        fieldValidationErrors.password = (passValid || value === "") ? '' : t('FormErrors:empty-password');
      break;

      case 'error':
        fieldValidationErrors.error = value
      break;
      default:
      break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      formSuccess: false,
      emailValid: emailValid,
      passValid: passValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passValid
    });
  }  

  queryApi = async (email, password) =>  {
    const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
    const {t, history} = this.props;

    await fetch(REACT_APP_API_URL + "/identification/" + email, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-API-KEY': REACT_APP_API_KEY,
        'AUTH-PW': password,
        "Accept" : "*/*"
      }
    })
    .then(res => res.json())
    .then(
      (result) => {

        if (result.reponse === "Ok") {
          var decoded = decodeToken(result.data);
          var expired = isExpired(result.data);

          if (decoded !== null && !expired) {
            if (email === decoded.data.CODE) {
              localStorage.setItem('token', JSON.stringify({
                data: result.data,
                iat: decoded.iat * 1000,
                expires: decoded.exp * 1000,
                user: decoded.data.CODE
              }))

              localStorage.setItem('loggedIn', true)

              this.setState({
                formSuccess: true,
                successMessage: t("FormSuccess:login-success")
              });

              setTimeout(function() {
                history.push({
                  pathname: "/dashboard"
                })
              }, 2000)
              

            } else {
              
              this.setState({
                formSuccess: false,
                formErrors : {error:t("FormErrors:login-failed")}
              });
  
            }
          } else {
            this.setState({
              formSuccess: false,
              formErrors: {
                error: {error:t("FormErrors:token-failed")}
              }
            });
          }

        } else {
          let trialLimit = Number(localStorage.getItem('trialLimit'));

          if (trialLimit >= 2) {
            let submitTimeLimit = Number(localStorage.getItem('submitTimeLimit'));
            let currentDate = new Date();
            let minutesToAdd = 2;
            let futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);
          
            if (submitTimeLimit === 0) {
              localStorage.setItem('submitTimeLimit', futureDate.getTime());
            }
  
          } else {
            localStorage.setItem('trialLimit', trialLimit+1)
          }

          let errorMessage = "";
          if (result.reponsetexte === "Api key refuse ou manquant") {
            errorMessage = t("FormErrors:missing-api-key")
          }

          if (result.reponsetexte === "mail obligatoire") {
            errorMessage = t("FormErrors:mandatory-email")
          }

          if (result.reponsetexte === "compte non trouve") {
            errorMessage = t("FormErrors:account-not-found")
          }

          if (result.reponsetexte === "Identification erronee") {
            errorMessage = t("FormErrors:login-failure")
          }

          this.setState({
            formSuccess: false,
            formErrors: {
              error: errorMessage
            }
          });
        
        }
      },
      (error) => {

        //console.log(error)
        this.setState({
          formSuccess: false,
          formErrors: {
            error: t("FormErrors:connection-issue")
          }
        });
      }
    )
  }

  
  togglePassword = (e) => {
    e.preventDefault()
    this.setState({
      passwordShown : !this.state.passwordShown
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.onVerify()
  }
  
  render() {
    const {i18n, t} = this.props;
    
      return (
        <div className="connect">

          <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
            <title>{t('Login:title')}</title>
            <meta name="description" content={t("Login:desc")} />
            </Helmet>
          </HelmetProvider>
          
          <LanguageSwitcher />

          <div className="wrapper">

            <form onSubmit={this.handleSubmit}>
              
              <Logo className='logo' />

              <div className="form">
              
                <h2>{t('Login:login')}</h2>
                <div className="app-title">{t('Login:app-title')}</div>
                
                <FormErrors formErrors={this.state.formErrors} />
                <FormSuccess formSuccess={this.state.formSuccess} successMessage={this.state.successMessage} />

                <div className="fields">
                  <div className={`form-group ${errorClass(this.state.formErrors.email)}`}>
                      <label htmlFor="email">{t('Login:email-address')}
                        <input type="email" autoComplete="username" id="email" name="email" className="form-control" placeholder={t('Login:enter-email')} onChange={
                          (e) => {
                            handleUserInput(e, this)
                          }
                        } onKeyDown={
                          (e) => {
                            onFieldKeyDown(e, this)
                          }
                        } />
                      </label>
                  </div>
                  <div className={`form-group ${errorClass(this.state.formErrors.password)}`}>
                      <label htmlFor="password">{t('Login:password')}
                        <input id="password" autoComplete="current-password" name="password" type={this.state.passwordShown ? "text" : "password"} className="form-control" placeholder={t('Login:enter-password')} onChange={
                          (e) => {
                            handleUserInput(e, this)
                          }
                        } onKeyDown={
                          (e) => {
                            onFieldKeyDown(e, this)
                          }
                        } />
                        <button className={this.state.passwordShown ? "showHide on" : "showHide"} onClick={this.togglePassword}></button>
                      </label>
                  </div>

                  <Link className="nav-link text-left" to="/forgot-password">{t('Login:forgot-password')}</Link>
                </div>
              </div>
                
              <button type="submit" className="continue">{t('Login:submit')}</button>
            </form>

            <img className="desktop-all bg-login absolute" src={bgConnectPages} alt={parse(t("Login:login"))} width="246" height="935" />
          </div>
          
        </div>
      );

      
  }
}

export default withTranslation()(withGoogleReCaptcha(Login));
