import { useState, useEffect, useRef } from "react";
import { isExpired } from "react-jwt";
import parse from "html-react-parser";
import { checkCardName, checkText, replaceDot } from "../../components/Helpers";
import { useTranslation } from "react-i18next";

const TheftLossModule = (props) => {
    const { t } = useTranslation()
    const [hasBlockedAcccounts, setHasBlockedAcccounts] = useState(false);
    const [panelTransition, setPanelTransition] = useState("fadeOut");
    const cardsHTML = useRef(null);
    const {loaderState, hideAccounts, match, cards, setCards, editModalIsOpen} = props;
    
    useEffect(() => {
        if (loaderState === "off hidden" && (hideAccounts === "hide hidden" || document.body.classList.contains("desktop"))) {
            setTimeout(function() {
                setPanelTransition("fadeIn")
            }, 150)
        }
        return () => {
            setPanelTransition("fadeOut")
        }

    }, [loaderState, hideAccounts]);
    
    const blockCard = async (id, annexeid) => {
        const token = JSON.parse(localStorage.getItem("token"));
        
    
        if (!isExpired(token.data)) {

            if (!hasBlockedAcccounts) {
                
                setHasBlockedAcccounts(true)

                const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
                let url = ""
        
                if (Number(annexeid) === 0) {
                url = id
                } else {
                url = id + "/" + annexeid
                }
        
                await fetch(REACT_APP_API_URL + "/blocage/" + url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                        'X-API-KEY': REACT_APP_API_KEY,
                        "Authorization" : "Bearer " + token.data
                    }
                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {
                        if (result.reponse === "ok") {
        
                            if (cards !== null) {
        
                                if (Number(annexeid) === 0) {

                                    cards.blocageetat = result.data.blocageetat
                                    
                                } else {
            
                                    Object.keys(cards.codesannexes).forEach((i) => {
                                        if (annexeid !== undefined) {
                                            if (Number(cards.codesannexes[i].annexeid) === Number(annexeid)) {
                                            cards.codesannexes[i].blocageetat = result.data.blocageetat
                                            }
                                        }
                                        
                                    })
                                
                                }

                                setCards(cards);
                                setHasBlockedAcccounts(false)
                            }
                        }
                    },
                    (error) => {
                        return error
                    }
                )
            }
                
        } else {
            alert("Theft:token-expired")
        }
    }

    const blockCards = (e) => {
        
        const cardsToBlock = cardsHTML.current.querySelectorAll(".card")

        if (cardsToBlock.length > 0) {
            cardsToBlock.forEach((obj, i) => {
                if (cardsToBlock[i].querySelector("input:checked") !== null) {
                    let id = Number(cardsToBlock[i].getAttribute("data-id"));
                    let annexeid = Number(cardsToBlock[i].getAttribute("data-annexeid"));
                    blockCard(id, annexeid);
                }
            })
        }
        
    }

    const getCardInfo = (data, index) => {
        let limitemontant = null
        let limitetype = null
        let solde = null
        let name = null

        if(data?.codesannexes !== undefined && data.codesannexes.length > 0) {
            if (data.texteclient === "") {
                name = parse(t("Accounts:main-card-raw"))
            } else {
                name = parse(data.texteclient)
            }
            
        } else {
            name = checkText(checkCardName(data, (Number(index)+1)))
        }
    

        if (data.limitemontant !== "" && data.limitemontant !== "undefined" && data.limitemontant !== undefined && String(data.limitemontant) !== "0") {
            limitemontant = replaceDot(data.limitemontant) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")
        } else {
            limitemontant = ""
        }

        if (data.limitetype === "jour" || data.limitetype === "sejour" || data.limitetype === "aucune") {
            limitetype = parse(t("Accounts:" + data.limitetype))
        } else {
            limitetype = data.limitetype

            if (limitetype === "" || limitetype === undefined || limitetype === "undefined") {
                limitetype = parse(t("Accounts:no-limit"))
            }
        }

        if (data.disponible !== undefined && data.disponible !== "undefined" && data.disponible !== null && data.disponible !== "" && String(data.disponible) !== "0") {
            solde = parse(t("Accounts:remains") + " " + replaceDot(data.disponible) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
        } else {
            if ((data.limitemontant === "" || data.limitemontant === undefined) || data.solde === "0" || data.disponible === null) {
                solde = ""
            } else {
                if(data.disponible === null) {
                    solde = parse(t("Accounts:remains") + " 0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                } else {
                    solde = parse(t("Accounts:no-card-limit"))
                }
            }
                
        }
        
        let realannexeid = data?.annexeid === undefined ? 0 : data?.annexeid
        
        return (
          <>

            {Number(data.blocageetat) === 1 &&
                <div className="card-info flex flex-direction-column relative justify-content-center">
                    <div className="name bolder cl-grey-dark relative">{name}</div>
                    <div className="limit flex flex-direction-row relative justify-content-between">{parse(t("Accounts:blocked-card"))}</div>
                </div>
            }
            
          
            {Number(data.blocageetat) === 0 &&
                <>
                <label htmlFor={"card-" + realannexeid} className="label flex flex-direction-row relative">

                    <div className="label-styled bg-grey-light relative">
                        <input type="checkbox" id={"card-" + realannexeid} name={"card-" + realannexeid} />
                        <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor={"card-" + realannexeid}></label>
                    </div>
                    
                    <div className="card-info flex flex-direction-column relative justify-content-center">
                        <div className={`name bolder cl-turquoise relative`}>{name}</div>
                        <div className="limit flex flex-direction-row relative justify-content-between">
                            {limitemontant === "" &&
                            <>
                                <div className="amount relative">{parse(t("Accounts:no-limit"))}</div>
                                <div className="remaining relative">
                                    <i>{solde}</i>
                                </div>
                            </>
                            }

                            {limitemontant !== "" &&
                            <>
                                <div className="amount relative">{limitemontant} / {limitetype}</div>
                                <div className="remaining relative">
                                    <i>{solde}</i>
                                </div>
                            </>
                            }

                        </div>
                    </div>
                </label> 
                </>
            }
            
          </>
        )
    }

    return (
        <>
            {cards !== null &&
                <div ref={cardsHTML} className={`theft-loss-container panel-container relative ${panelTransition}${(editModalIsOpen !== undefined && editModalIsOpen) ? " modal-open" : ""}`}>
                    
                    <div className="panel-title theft desktop-all bolder-x relative">{parse(t("Theft:theft-loss"))}</div>

                    <div className="block-title bolder relative">{t("Theft:block-title")}</div>
                    <div className="block-p relative">{t("Theft:block-p")}</div>

                    <div className="cards-title bolder relative">{t("Accounts:main-card-raw")}</div>
                    <div className="cards flex flex-direction-row flex-wrap align-items-start justify-content-start relative">
                    
                    <div data-id={match.params.id} data-annexeid="0" className={`card flex flex-direction-row ${Number(cards.blocageetat) === 1 ? ' blocked bg-grey-light cl-grey-dark' : 'bg-white'}`}>{getCardInfo(cards)}</div>
                    </div>

                    {cards.codesannexes !== null && cards.codesannexes !== undefined && cards.codesannexes.length > 0 &&
                        <>
                        <div className="cards-title bolder relative">{t("Theft:other-cards")}</div>
                        
                        <div className="cards flex flex-direction-row flex-wrap align-items-start justify-content-start relative">
                        {cards.codesannexes.map((obj, i) => {
                        return (
                            <div key={obj.annexeid} data-id={match.params.id} data-annexeid={obj.annexeid} className={`card flex flex-direction-row ${Number(obj.blocageetat) === 1 ? ' blocked bg-grey-light cl-grey-dark' : 'bg-white'}`}>
                                {getCardInfo(obj, i)}
                            </div>
                        )
                        })}
                        </div>
                        </>
                    }

                    <div className="text-center block full-width"><button className="continue" onClick={()=> {
                        blockCards()
                    }}>{parse(t("BlockModal:block"))}</button></div>
                    
                    
                </div>
            }
        </>
    )
    
}

export default TheftLossModule