//-> Liste déroulante d'affichage des bracelets

import { Switch, Route } from "react-router-dom"
import CardList from "./CardList"

const CardLists = (props) => {
    const { firstactive, data, location } = props;

    let id = firstactive
    
    if (id === undefined) {
        if (data !== null) {
            id = firstactive !== undefined ? firstactive : data.comptes[0].id
        }
    }

    
    return(
        <Switch location={location}>

            <Route path={`/dashboard/:id/:annexeid`}>
                <CardList id={firstactive} {...props} />
            </Route>
            <Route path={`/dashboard/:id`}>
                <CardList id={props.firstactive} {...props} />
            </Route>
            {id !== undefined && id !== null &&
            <Route exact path={`/dashboard`}>
                <CardList id={id} {...props} />
            </Route>
            }

        </Switch>
    )

}

export default CardLists