import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Redirect, Switch, Route, useLocation } from 'react-router-dom';
import Login from './Login';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import { clearLocalStorage, toggleRecaptcha } from "../components/Helpers";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isExpired, decodeToken } from "react-jwt";
import i18n from '../i18n/config';
import '../assets/css/reset.css';
import '../assets/css/animations.css';

//-> Vue : Container de l'app
export const routes = [

  {
    path: "/login",
    component: Login,
    componentText: "Login"
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    componentText: "ForgotPassword"
  },
  {
    path: "/dashboard",
    component: Dashboard,
    componentText: "Dashboard"
  },  
  {
    path: "/first-login",
    component: ResetPassword,
    componentText: "ResetPassword"
  },
  {
    path: "/password-reset",
    component: ResetPassword,
    componentText: "ResetPassword"
  }
];

//-> Animation des transitions entre vues
const AnimatedSwitch = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState("fadeIn");
  
  useEffect(() => {
      if (location !== displayLocation) {
        if (location.pathname.indexOf("/dashboard") === -1) {
          setTransitionStage("fadeOut");
        }

        if (location.pathname.indexOf("/reload") === -1) {
          localStorage.removeItem("paylinetoken")
          localStorage.removeItem("paylinestate")
        }
      }
  }, [location, displayLocation]);

  let currentComponent = "";

  Object.keys(routes).forEach((i) => {
    if (location.pathname.indexOf(routes[i].path) > -1) {
      currentComponent = routes[i].componentText
    }
  })
  const nodeRef = React.useRef(null)

  return (
    <div>

      <div ref={nodeRef} className={`component ${transitionStage} ${currentComponent.toLowerCase()}`} onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransitionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}>
      <Switch location={(currentComponent.toLowerCase() === "dashboard" ? location : displayLocation)}>
                  
        <Route path="/(first-login|password-reset)/:token" render={(props) => {
            toggleRecaptcha(true);

            return( 
                <ResetPassword {...props}/>
            )
          }
        } />
        <Route path="/login" render={(props) => {

            let loggedIn = Boolean(localStorage.getItem('loggedIn'))
            let token = JSON.parse(localStorage.getItem('token'))
                

            if (loggedIn && decodeToken(token.data) !== null) {
              
              if (isExpired(token.data)) {
                toggleRecaptcha(true);
                clearLocalStorage();

                return(
                  <Login {...props} />
                )
              } else {
                toggleRecaptcha(false);

                return(
                  <Redirect to="/dashboard" />
                )
              }
              
            } else {
              toggleRecaptcha(true);
              return(
                <Login {...props} />
              )
            }
            
          }
        } />
        <Route path="/forgot-password" render={(props) => {
            toggleRecaptcha(true);

            return(
                <ForgotPassword {...props}/>
            )
          }
        } />

        <Route exact path="/dashboard" render={(props) => {

            let loggedIn = Boolean(localStorage.getItem('loggedIn'))
            let token = JSON.parse(localStorage.getItem('token'))

            if (loggedIn && decodeToken(token.data) !== null) {
              
              if (isExpired(token.data)) {
                toggleRecaptcha(true);
                clearLocalStorage();                

                return(
                  <Redirect to="/login" />
                )
              } else {
                toggleRecaptcha(false);
                return(
                  <Dashboard location={location} displayLocation={displayLocation} {...props} />
                )
              }
              
            } else {
              toggleRecaptcha(true);
              return(
                <Redirect to="/login" />
              )
            }

          }
        } />

        <Route path="/dashboard/:id" render={(props) => {
            let loggedIn = Boolean(localStorage.getItem('loggedIn'))
            let token = JSON.parse(localStorage.getItem('token'))

            if (loggedIn && decodeToken(token.data) !== null) {
              
              if (isExpired(token.data)) {
                
                toggleRecaptcha(true);
                clearLocalStorage();

                return(
                  <Redirect to="/login" />
                )
              } else {
                toggleRecaptcha(false)
                return(
                  <Dashboard 
                    location={location} 
                    displayLocation={displayLocation} 
                    transitionStage={transitionStage} 
                    setTransitionStage={setTransitionStage} 
                    id={props.match.params.id} 
                    {...props} 
                  />
                )
              }
              
            } else {
              toggleRecaptcha(true)
              return(
                <Redirect to="/login" />
              )
            }

          }
        } />

        
        <Redirect strict exact from="/" to="/login" />
        <Redirect strict exact from="/(first-login|password-reset)/" to="/login" />
        <Redirect strict exact from="/(first-login|password-reset)" to="/login" />

        <Route path="*" render={(props) => {
            toggleRecaptcha(false)
            return(
                <NotFound {...props}/>
            )
          }
        } />

        {Object.keys(routes).forEach((i) => {
          if (location.pathname.indexOf(routes[i].path) > -1) {
            currentComponent = routes[i].componentText;
          }
        })}
      </Switch>
      
    </div>
    
    
   </div>
  )
};

//-> App
function App () {

  useEffect(() => { 
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  

  const updateDimensions = () => {
    const width = window.innerWidth
    if (width > 1024) {
      document.body.classList.add("desktop")
    } else {
      document.body.classList.remove("desktop")
    }
  }

  return (
    <div className="App">
      <Router>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
          language={i18n.language}
          scriptProps={{
            async: true,
            id: "google-recaptcha-v3",
            defer: true,
            appendTo: 'head',
            nonce: undefined
          }}
        >
          <AnimatedSwitch/>
        </GoogleReCaptchaProvider>
      </Router>
    </div>
  );
}

export default withTranslation()(App);
