//-> Bracelet individuel pour un compte donné

import { useParams } from "react-router-dom"
import parse from "html-react-parser"
import Select, { components } from 'react-select'
import { useTranslation } from "react-i18next"
import { checkCardName, checkText, replaceDot } from "../../components/Helpers"

const CardList = (props) => {
    const { annexeid } = useParams();
    const { t } = useTranslation();
    const { cards } = props;
    
    if (cards !== null) {
        const cardsSelect = []

        const displayUserOperations = (idMouv) => {
            let finalURI = ""
            
            setTimeout(function() {
                if (props.url === "/dashboard") {
                    finalURI = idMouv !== "" ? props.url + "/" + props.firstactive + "/" + idMouv : props.url
                } else {
                    finalURI = idMouv !== "" ? props.url + "/" + idMouv : props.url
                }
    
    
                if (props.history !== undefined) {
                    props.history.push({
                        pathname: finalURI
                    })
    
                }
            }, 200)
            
        }

        const customFilter = (option, searchText) => {
            let label = option.label
            
            if (label !== null) {
                if (label.toLowerCase().includes(searchText.toLowerCase())) {
                    return true;
                  } else {
                    return false;
                  }
            } else {
                return false;
            }
            
        }

        const CustomOption = props => {
            const { data, innerRef, innerProps } = props;
          
            let limitemontant = null
            let limitetype = null
            let solde = null

            if (data.limitemontant !== "undefined" && data.limitemontant !== undefined && String(data.limitemontant) !== "0") {
                limitemontant = replaceDot(data.limitemontant) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")
            } else {
                limitemontant = "0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")
            }

            if(data.limitetype === "" || data.limitetype === "undefined" || data.limitetype === undefined) {
                limitetype = parse(t("Accounts:no-limit"))
            } else {
                limitetype = parse(t("Accounts:" + data.limitetype))
            }

            if (data.disponible !== undefined && data.disponible !== "undefined" && data.disponible !== null && String(data.disponible) !== "0") {
                solde = parse(t("Accounts:remains") + " " + replaceDot(data.disponible) + " " +  (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
            } else {
                if ((data.limitemontant === "" || data.limitemontant === undefined) || data.solde === "0") {
                    solde = ""
                } else {
                    if(data.disponible === null) {
                        if (data.limitetype === "") {
                            solde = ""
                        } else {
                            solde = parse(t("Accounts:remains") + " 0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                        }
                    } else {
                        solde = parse(t("Accounts:remains") + " " + replaceDot(data.disponible) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                    }
                }

            }

            return (
              <div className={`card${Number(data.blocageetat) === 1 ? ' blocked' : ''}${data.start ? ' start' : ''}${(props.isSelected || (data.start && annexeid === undefined)) ? ' selected' : ''}`} ref={innerRef} {...innerProps}>
               
                {Number(data.blocageetat) === 1 &&
                    <div className="card-info">
                        <div className="name">{data.label}</div>
                    </div>
                }
                
                {Number(data.blocageetat) === 1 &&
                    <div className="limit">{parse(t("Accounts:blocked-card"))}</div>
                }

                {Number(data.blocageetat) === 0 &&
                    <div className="card-info">
                        <div className={`name ${data.value !== false && data.value !== "" ? "cl-turquoise" : ""}`}>{data.label}</div>
                    </div>
                }
                
                {(Number(data.blocageetat) === 0 && !data.start && data.value !== false && data.value !== "") &&
                    <div className="limit">
                        {data.value === 0 &&
                        <>
                            <div className="amount">{parse(t("Accounts:no-limit"))}</div>
                            <div className="remaining">
                                <i>{solde}</i>
                            </div>
                        </>
                        }

                        {data.value !== 0 &&
                        <>
                                {limitemontant !== "0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€") &&
                                    <>{limitemontant} / </>
                                }

                                {limitetype}
                                
                            <div className="remaining">
                                <i>{solde}</i>
                            </div>
                        </>
                        }

                    </div>
                }
               
              </div>
            )
        }

     
        const CustomSingleValueMobile = (propsTest) => {
            const { data, innerRef, innerProps } = propsTest;
            let finalData = cards
            let limitemontant = null
            let limitetype = null
            let solde = null


            if (annexeid !== undefined && Number(annexeid) > 0) {
                Object.keys(cards.codesannexes).forEach((i) => {
                    if(Number(annexeid) === Number(cards.codesannexes[i].annexeid)) {
                        finalData = cards.codesannexes[i]
                    }
                });

            }

            
            let name = parse(finalData.texteclient)

            if (Number(annexeid) === 0) {
                name = parse(finalData.texteclient)
            }

            if (annexeid === undefined) {
                name = parse(t("Accounts:all-cards"))
            }

            if (name === "") {
                name = parse(data.label)
            }
            
           
            if (finalData.limitemontant !== "undefined" && finalData.limitemontant !== undefined && String(finalData.limitemontant) !== "0") {
                limitemontant = replaceDot(finalData.limitemontant) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")
            } else {
                limitemontant = "0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€")
            }

            if(finalData.limitetype === "" || finalData.limitetype === "undefined" || finalData.limitetype === undefined) {
                limitetype = parse(t("Accounts:no-limit"))
            } else {
                limitetype = parse(t("Accounts:" + finalData.limitetype))
            }

            if (finalData.disponible !== undefined && finalData.disponible !== "undefined" && finalData.disponible !== null && finalData.disponible !== "" && finalData.disponible !== "0") {
                solde = parse(t("Accounts:remains") + " " + replaceDot(finalData.disponible) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
            } else {
                if ((finalData.limitemontant === "" || finalData.limitemontant === undefined) || finalData.solde === "0" || finalData.disponible === null || finalData.limitetype === "aucune") {
                    solde = ""
                } else {
                    if(finalData.disponible === null) {
                        if (finalData.limitetype === "") {
                            solde = ""
                        } else {
                            solde = parse(t("Accounts:remains") + " 0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                        }
                    } else {
                        solde = parse(t("Accounts:remains") + " " + replaceDot(finalData.disponible) + " " + (cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€"))
                    }
                }
            }

            return (
              <components.SingleValue {...propsTest}>
                <div className={`single card${(Number(finalData.blocageetat) === 1 ? ' blocked' : '')}${finalData.start ? ' start' : ''}${(props.isSelected || (finalData.start && annexeid === undefined)) ? ' selected' : ''}`} ref={innerRef} {...innerProps}>
                <div className={`card-info${finalData.value === false ? " full-width" : ""}${annexeid === undefined ? " no-picto" : ""}`}>
                    <div className="name">{name}</div>
                </div>

                {Number(finalData.blocageetat) === 1  && annexeid !== undefined && 
                    <div className="limit mobile-all">{parse(t("Accounts:blocked-card"))}</div>
                }
                {(Number(finalData.blocageetat) === 0 && !finalData.start && finalData.value !== false && finalData.value !== "" && annexeid !== undefined) &&
                    <div className="limit">
                        {finalData.value === 0 &&
                        <>
                        
                            <div className="amount">{parse(t("Accounts:no-limit"))}</div>
                            <div className="remaining">
                                <i>{solde}</i>
                            </div>
                        </>
                        }

                        {finalData.value !== 0 &&
                        <>
                            <div className="amount">
                                {limitemontant !== "0 "+(cards?.monnaie !== "" ? cards?.monnaie.toUpperCase() : "€") &&
                                    <span className="uppercase">{limitemontant} / </span>
                                }

                                {limitetype}
                            </div>
                            <div className="remaining">
                                <i>{solde}</i>
                            </div>
                        </>
                        }


                    </div>
                }
                
              </div>
              </components.SingleValue>
            )
        }

        const CustomSingleValueDesktop = (props) => {
            const { data, innerRef, innerProps } = props;
          
            return (
              <components.SingleValue {...props}>
                <div className={`single card${(Number(data.blocageetat) === 1 ? ' blocked' : '')}`} ref={innerRef} {...innerProps}>
                    <div className="card-info">
                        <div className="name">{parse(t("Accounts:all-cards"))}</div>
                    </div>
                </div>
              </components.SingleValue>
            )
        }

        const NoOptionsMessage = props => {
            const { innerRef, innerProps } = props;

            return (
                <components.NoOptionsMessage {...props}>
                <div className={`card no-card`} ref={innerRef} {...innerProps}>
                    <div className="card-info full-width">
                        <div className="name text-center">{parse(t("Accounts:no-card"))}</div>
                    </div>
                </div>
                </components.NoOptionsMessage>
            )
        }

        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              backgroundColor: '#ffffff',
              color: "#222222",
              padding:0,
              margin:0,
              borderRadius:5,
              width:"auto",
              "&:hover": {
                color: "#222222",
                backgroundColor: "#ffffff"
              }
            }),
            control: () => ({
              width: "auto",
              margin:0,
              padding:0
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: '#222222',
                backgroundColor:'#fff',
                padding:0,
                margin:0,
                borderRadius:5
            }),
            menu: (provided, state) => ({
                ...provided,
                padding: "3px 3px 3px 0",
                margin:0,
                boxShadow:"1px 2px 4px #00000029",
                overflow: "hidden"
                
            }),
            container: (provided, state) => ({
                ...provided,
                padding: 0,
                margin:0
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                padding: 0,
                margin:0
            }),
            menuList: (provided, state) => ({
                ...provided,
                maxHeight:200,
                overflowY:"auto",
                overflowX:"hidden",
                "&::-webkit-scrollbar" : {
                    width:9,
                    backgroundColor:"#f1f1f1"
                },
                "&::-webkit-scrollbar-thumb" : {
                    backgroundColor:"#4F9BAA"
                },
                "&::-webkit-scrollbar-thumb:hover" : {
                    backgroundColor:"#FFCF4B"
                }
            }),

        }


        const customStylesDesktop = {
            option: (provided, state) => ({
              ...provided,
              backgroundColor: '#ffffff',
              color: "#222222",
              margin:0,
              padding:0,
              "&:hover": {
                color: "#222222",
                backgroundColor: '#ffffff',
              }
            }),
            control: () => ({
              width: "auto",
              margin:0,
              padding:0
            }),
            singleValue: (provided, state) => ({
                ...provided,
                display:"none"
            }),
            menu: (provided, state) => ({
                ...provided,
                position: "relative",
                padding: "3px 3px 3px 0",
                margin:0,
                boxShadow:"1px 2px 4px #00000029",
                overflow:"hidden"
            }),
            container: (provided, state) => ({
                ...provided,
                padding: 0,
                margin:0,
                
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                padding: 0,
                margin:0
            }),
            menuList: (provided, state) => ({
                ...provided,
                padding: 0,
                margin:0,
                maxHeight:355,
                borderRadius: "0 0 5px 5px",
                overflowY:"auto",
                overflowX:"hidden",
                "&::-webkit-scrollbar" : {
                    width:9,
                    backgroundColor:"#f1f1f1"
                },
                "&::-webkit-scrollbar-thumb" : {
                    backgroundColor:"#4F9BAA"
                },
                "&::-webkit-scrollbar-thumb:hover" : {
                    backgroundColor:"#FFCF4B"
                }
            }),

        }

        if (cards.codesannexes.length > 0) {
            cardsSelect[0] = {}
            cardsSelect[0].value = ""
            cardsSelect[0].blocageetat = false
            cardsSelect[0].label = parse(t("Accounts:all-cards"))
            cardsSelect[0].start = true

            cardsSelect[1] = {}
            cardsSelect[1].value = 0
            cardsSelect[1].limitetype = cards.limitetype
            cardsSelect[1].limitemontant = cards.limitemontant
            cardsSelect[1].disponible = cards.disponible
            cardsSelect[1].solde = cards.solde
            cardsSelect[1].blocageetat = cards.blocageetat
            cardsSelect[1].label = cards.texteclient !== "" ? parse(cards.texteclient) : parse(t("Accounts:main-card-raw"))
            cardsSelect[1].start = false
        
            Object.keys(cards.codesannexes).forEach((i) => {
                let displayVal = String(Number(i)+1)
                let name = checkText(checkCardName(cards.codesannexes[i]), displayVal)
                let obj = cards.codesannexes[i]
                let tmp = {}
                tmp.start = false
                tmp.value = obj.annexeid
                tmp.limitetype = obj.limitetype
                tmp.limitemontant = obj.limitemontant
                tmp.solde = obj.solde
                tmp.disponible = obj.disponible
                tmp.blocageetat = Number(obj.blocageetat)
                tmp.label = name !== "" ? parse(name) : parse(t("Accounts:card") + " " + displayVal)
                cardsSelect.push(tmp)
            })
                
        } else {
            cardsSelect[0] = {}
            cardsSelect[0].value = ""
            cardsSelect[0].blocageetat = false
            cardsSelect[0].label = parse(t("Accounts:all-cards"))
            cardsSelect[0].start = true

            cardsSelect[1] = {}
            cardsSelect[1].value = 0
            cardsSelect[1].limitetype = cards.limitetype
            cardsSelect[1].limitemontant = cards.limitemontant
            cardsSelect[1].solde = cards.solde
            cardsSelect[1].disponible = cards.disponible
            cardsSelect[1].blocageetat = cards.blocageetat
            cardsSelect[1].label = cards.texteclient !== "" ? parse(cards.texteclient) : parse(t("Accounts:main-card-raw"))
            cardsSelect[1].start = false
        }

        // useEffect(() => {
        //     props.setCards()
        // },[])

        return (
            <div className="cards">
                <Select 
                    className="mobile-all"
                    classNamePrefix="select-mobile-all"
                    placeholder={false}
                    isSearchable={false}
                    filterOption={customFilter}
                    components={{ NoOptionsMessage, Option: CustomOption, SingleValue: CustomSingleValueMobile, ClearIndicator: () => null, IndicatorSeparator: () => null, DropdownIndicator: () => null }}
                    styles={customStyles}
                    options={cardsSelect} 
                    onChange={(e) => {
                        displayUserOperations(e.value)
                    }}
                    defaultValue={() => {
                        let defVal = cardsSelect.find(({ value }) => {
                            if (annexeid === undefined) {
                                return 0
                            } else {
                               return Number(value) === Number(annexeid)
                            }
                        })
                        
                        if (Number(annexeid) === 0) {

                            return {
                                start:false,
                                value: 0,
                                limitetype: cardsSelect[1].limitetype,
                                limitemontant: cardsSelect[1].limitemontant,
                                solde: cardsSelect[1].solde,
                                disponible: cardsSelect[1].disponible,
                                blocage: cardsSelect[1].blocageetat,
                                label: cardsSelect[1].label !== "" ? parse(cardsSelect[1].label) : parse(t("Accounts:main-card")),
                                isSelected: true
                            }

                        } else {

                            return (defVal !== undefined) ? defVal : {
                                start:true,
                                value: "",
                                limitetype: "",
                                limitemontant: "",
                                solde: "",
                                disponible: "",
                                blocage: false,
                                label: parse(t("Accounts:all-cards")),
                                isSelected: true
                            }
                        }
                                                    
                    }}

                />

                <Select
                    menuIsOpen={true}
                    className="desktop-all"
                    classNamePrefix="select-desktop-all"
                    placeholder={false}
                    isSearchable={false}
                    filterOption={customFilter}
                    components={{ NoOptionsMessage, Option: CustomOption, SingleValue: CustomSingleValueDesktop, ClearIndicator: () => null, IndicatorSeparator: () => null, DropdownIndicator: () => null }}
                    styles={customStylesDesktop}
                    options={cardsSelect} 
                    onChange={(e) => {
                        displayUserOperations(e.value)
                    }}
                    defaultValue={() => {
                        let defVal = cardsSelect.find(({ value }) => {
                            if (annexeid === undefined) {
                                return false
                            } else {
                                return Number(value) === Number(annexeid)
                            }
                        })

                        if (Number(annexeid) === 0) {
                            return {
                                start:false,
                                value: 0,
                                limitetype: cardsSelect[1].limitetype,
                                limitemontant: cardsSelect[1].limitemontant,
                                solde: cardsSelect[1].solde,
                                disponible: cardsSelect[1].disponible,
                                blocage: cardsSelect[1].blocageetat,
                                label: cardsSelect[1].label !== "" ? parse(cardsSelect[1].label) : parse(t("Accounts:main-card")),
                                isSelected: true
                            }
                        } else {

                            return (defVal !== undefined) ? defVal : {
                                start:true,
                                value: "",
                                limitetype: "",
                                limitemontant: "",
                                solde: "",
                                disponible: "",
                                blocageetat: false,
                                label: parse(t("Accounts:all-cards")),
                                isSelected: true
                            }
                        }
                        
                    }}

                />
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
    
}

export default CardList