import React, { Suspense } from 'react';
import i18n from 'i18next';
import ReactDOM from 'react-dom';
import App from './elements/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './tests/reportWebVitals';
import './assets/css/index.css';

//-> Affichage de l'app
ReactDOM.render(
  <React.StrictMode>
      <Suspense>
        <App />
      </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

//-> Gestion du serviceworker
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  onUpdate: registration => {

    let div = document.createElement("div");
    div.className = "update-popup";

    let html = '<div class="popup-content"><p>' + i18n.t("Login:app-update") + '</p><button>' + i18n.t("Login:update") + '</button></div>';
    div.innerHTML = html;

    document.body.appendChild(div);
    
    let popup = document.querySelector(".update-popup");
    if (popup != null) {
      popup.classList.add("on");
    }
    
     console.info('service worker on update state')

    let btn = popup.querySelector("button");
    if (btn != null) {
      btn.addEventListener("click", function() {
        registration.unregister().then(() => {
          window.location.reload()
        })
      }, false)
    }


  },
  onSuccess: registration => {
    console.info('service worker on success state')
    console.log(registration)
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
