import React from 'react';
import parse from 'html-react-parser';
import { withTranslation, useTranslation } from 'react-i18next';

//-> Gestion des messages d'erreur sur le formulaire de login
const FormErrors = ({formErrors}) => {
    const {t} = useTranslation();

    return (
        <div className='form-errors'>
            {Object.keys(formErrors).map((fieldName, i) => {
                if(formErrors[fieldName].length > 0){

                    if (fieldName === "extraText") {
                        return (
                            <p key={i}>{parse(formErrors[fieldName])}</p>
                        )
                    } else {
                        return (
                            <p className="bg-error2 text-center" key={i}><strong>{t("FormErrors:error").toUpperCase()}</strong> {parse(formErrors[fieldName])}</p>
                        )    
                    }
                        
                } else {
                    return '';
                }
                
            })}
        </div>
    )
}

export default withTranslation()(FormErrors)