//-> Vue : Page 404

import React, { Component } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LanguageSwitcher from '../components/LanguageSwitcher';
import { withTranslation } from 'react-i18next';
import bgNotFoundMobile from '../assets/images/not-found-mobile.png';
import bgNotFoundDesktop from '../assets/images/not-found-desktop.png';
import '../assets/css/general/connexion.css';

class NotFound extends Component {

    render () {
      const {i18n, t} = this.props

      return (
        <div className="connect err404">
        
          <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
            <title>{t('NotFound:title')}</title>
            <meta name="description" content={t("NotFound:desc")} />
            </Helmet>
          </HelmetProvider>

          <LanguageSwitcher />

          <div className="wrapper flex align-items-center justify-content-evenly flex-direction-row">
          
            <picture className="bg relative">
                <source media="(min-width: 1025px)" srcSet={bgNotFoundDesktop} />
                <img alt="" src={bgNotFoundMobile} />
            </picture>
            
            <div className="relative flex align-items-center justify-content-center flex-direction-column">

              <div className="form text-center">
                  <div className="app-title">{t('NotFound:oops')}</div>
                  <h1 className="text-center">{t("NotFound:page-not-found")}</h1>
                  <a className="back-home uppercase mulish-font bolder-x" href="/login">&lt;&lt;&nbsp;{t("NotFound:back-home")}</a>
              </div>
              
            </div>
          </div>
        </div>
      )
    }
}

export default withTranslation()(NotFound);
