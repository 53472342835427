//-> Page Contact (formulaire)

import { useState, useEffect } from "react";
import { isExpired } from "react-jwt";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const Contact = (props) => {
    const [subject, setSubject] = useState("");
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [requestInitiated, setRequestInitiated] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [panelTransition, setPanelTransition] = useState("fadeOut");
    const { cards, data, loaderState, hideAccounts, editModalIsOpen } = props;
    
    
    useEffect(() => {
        if (loaderState === "off hidden" && (hideAccounts === "hide hidden" || document.body.classList.contains("desktop"))) {
            setTimeout(function() {
                setPanelTransition("fadeIn")
            }, 150)
        }
        return () => {
            setPanelTransition("fadeOut")
        }

    }, [hideAccounts, loaderState]);



    const contact = async () => {
        const token = JSON.parse(localStorage.getItem("token"));
        
        if (!isExpired(token.data)) {
            
            if (!requestInitiated) {

                setRequestInitiated(true)
           
                const { REACT_APP_CONTACT_PHP } = process.env;
        
                await fetch(REACT_APP_CONTACT_PHP, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept' : '*/*',
                    },
                    body : JSON.stringify({ 
                        "mailsite" : cards.mailsite,
                        "nomprenom" : data.nomprenom,
                        "mail" : data.mail,
                        "subject": subject,
                        "message": message,
                    })

                }).then(function(response){
                    return response.json();
                }).then(
                    (result) => {

                        if (result.status) {
                            setRequestInitiated(false)
                            setRequestStatus(true)
                        } else {
                            setRequestInitiated(false)
                            setRequestStatus(false)
                        }

                    },
                    (error) => {
                        setRequestStatus(false)
                        return error
                    }
                )
            }
                
        } else {
            setRequestStatus(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (subject !== "" && message !== "") {
            contact()
        } else {
            alert(t("Contact:enter-details"))
        }
    }

    const onEnterPress = (e) => {
      if (e.key === "Enter") {
        handleSubmit(e)
      }
    }
    
    return (
        <>
            {cards !== null &&
                <div className={`contact-container panel-container flex flex-direction-column justify-content-start align-items-stretch relative ${panelTransition}${editModalIsOpen ?  "modal-open" : ""}`}>
                    
                    <div className="panel-title contact desktop-all bolder-x relative">{parse(t("Contact:contact"))}</div>
                    
                    <div className="block-title bolder relative">{t("Contact:block-title")}</div>

                    <div className="contact-wrapper">
                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                            
                                <label className="subject" htmlFor="subject">{t("Contact:subject")}
                                    <input type="text" maxLength={120} name="subject" id="subject" className="form-control" onKeyDown={onEnterPress} onChange={e => {
                                        setSubject(e.target.value)
                                    }} /> 
                                </label>

                                <label className="message" htmlFor="message">{t("Contact:message")}
                                    <textarea maxLength={500} name="message" id="message" className="form-control" onChange={e => {
                                        setMessage(e.target.value)
                                    }}></textarea>
                                </label>

                            </div>

                            {requestStatus !== null && requestStatus &&
                                <div className='form-success'>
                                    <p className="bg-success2 text-center"><span className='valid'></span>{parse(t("Contact:email-sent-successfully"))}</p>
                                </div>
                            }
                    
                            {requestStatus !== null && !requestStatus &&
                            <div className='form-errors'>
                                <p className="bg-error2 text-center">
                                <strong>{parse(t("FormErrors:error").toUpperCase())}</strong>&nbsp;
                                {parse(t("Contact:email-not-sent"))}
                                </p>
                            </div>
                            }

                            <div className="text-center block full-width"><button className="continue" type="submit">{t("Contact:send")}</button></div>

                        </form>

                    </div>

                    <div style={{height:"1px"}}></div>

                </div>
                
            }
        </>
    )
    
}

export default Contact