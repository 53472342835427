//-> Affichage des vignettes de compte

import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { getAccountsByStatus, formatDate, replaceDot } from "../../components/Helpers";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const DisplayAccounts = (props) => {
    const [currentSwiper, setCurrentSwiper] = useState(null);
    const { t } = useTranslation();
    const { id, data, active, history, cards } = props;

    const getAccountSlideIndex = () => {
        let realIndex = 0;
        
        if (data !== undefined && id !== undefined) {
            
            let tmpIxArray = []

            for (let value in data.comptes) {
                let obj = data.comptes[value]
                if (active) {
                    if (obj.cloturele === '') {
                        tmpIxArray.push(obj.id)
                    }
                } else {
                    if (obj.cloturele !== undefined && obj.cloturele !== '') {
                        tmpIxArray.push(obj.id)
                    }
                }
            }
  
            for (let i=0;i<tmpIxArray.length;i++) {
                if (Number(tmpIxArray[i]) === Number(id)) {
                    realIndex = Number(i)
                    break
                }
            }
            return realIndex
        }
    }

    const checkText = (text) => {
        if (text !== "") {
            return parse(t("Accounts:card") + " <br/><span class='text-transform-none bold'>" + text + "</span>")
        } else {
            return parse(t("Accounts:main-card"))
        }
    }

    useEffect(() => {

        if (currentSwiper !== null) {

            const updateSwiper = () => {
                const width = window.innerWidth
                setTimeout(function() {
                    if (width > 1024) {
                        currentSwiper.init(currentSwiper)
                    } else {
                        currentSwiper.destroy(false, true)
                    }
                }, 300)
            }

            updateSwiper();
            window.addEventListener("resize", updateSwiper)

            return () => {
                window.removeEventListener("resize", updateSwiper);
            }
        }
    }, [currentSwiper])


    useEffect(() => {

        const clickSlider = (swiper) => {
            setTimeout(function() {
                if (swiper.activeIndex !== undefined) {
                    let active =  swiper.slides[swiper.activeIndex]
                    if (active !== undefined) {
                        let a = active.querySelector("a")
                        if (a !== null) {
                            if (history !== undefined) {
                                history.push({
                                    pathname: a.getAttribute("href")
                                })
                            } else {
                                a.click()
                            }
                        }
                    }
                }
            }, 300)
        }

        
        // const updateSwiperPos = () => {
        //     if (currentSwiper !== null) {
        //         setTimeout(function() {
        //             currentSwiper.slideTo(getAccountSlideIndex(props), false, false)
        //         }, 300)
        //     }
        // }


        if (currentSwiper !== null) {
            currentSwiper.on("slideChange", clickSlider)
        }

        //window.addEventListener("popstate", updateSwiperPos)

        return () => {
            if (currentSwiper !== null) {
                currentSwiper.off("slideChange", clickSlider)
            }
            
            //window.removeEventListener("popstate", updateSwiperPos)
        }

    }, [currentSwiper, history])


    return (
    <div>
        {getAccountsByStatus(data, active) !== null && Number(getAccountsByStatus(data, active)) > 1 && 
        
            <div className="nav relative flex flex-direction-row justify-content-center align-items-center">
                <div className="prev relative">prev</div>
                <div className="next relative">next</div>
            </div>
        }

        <div className="accounts-wrapper">
        <Swiper
            speed={100}
            spaceBetween={10}
            centeredSlides={true}
            slidesPerView={1}
            watchSlidesProgress
            initialSlide={getAccountSlideIndex()}
            navigation={{
                prevEl: ".prev",
                nextEl: ".next"
            }}
            modules={[Navigation]}
            onAfterInit={(swiper) => {
                setCurrentSwiper(swiper)
            }}
        >
        
        {props?.data && data.comptes.map((obj, i) => {
            return((active ? obj.cloturele === '' : obj.cloturele !== undefined && obj.cloturele !== '') ?
            <SwiperSlide key={i}>
                <NavLink 
                    to={`/dashboard/${obj.id}`} 
                    className={(isActive) => {
                        return("account flex justify-content-evenly align-items-center flex-direction-column")
                    }}

                    onClick={(e) => {
                        
                        if (!document.body.classList.contains("desktop")) {
                            e.preventDefault()
                            window.open(e.target.href, "_self")
                        }
                    }}
                >
                    {obj.urlsite !== "" &&
                        <div className="picture"><img title={obj.etablissement} src={obj.urlsite} alt="" width="" height="63" /></div>
                    }

                    {obj.urlsite === "" &&
                        <div className="estab bolder">{obj.etablissement}</div>
                    }
                    
                    <div className="owner mulish-font bold full-width">{obj.nomprenom}</div>

                    {active &&
                    <div className="full-width relative">
                        <div className="created italic full-width">{formatDate(obj.creele)}</div>
                        <div className="bottom flex flex-wrap justify-content-between align-items-center flex-direction-row full-width">
                            {cards !== null &&
                                <div className="first-card uppercase">
                                    {(getAccountSlideIndex() === i && Number(cards.id) === Number(id)) ? checkText(cards.texteclient) : checkText(obj.texteclient)}
                                </div>
                            }
                            <div className="solde uppercase">{parse(t("Accounts:balance"))} <span>{replaceDot(obj.solde)}&nbsp;<i>{(obj?.monnaie !== "" ? obj?.monnaie.toUpperCase() : (cards?.monnaie !== "" ? cards?.monnaie : "€"))}</i></span></div>
                        </div>
                    </div>
                    }

                    {!active &&
                        <div className="closed-account">
                            <span className="cl-black bolder-x uppercase">{parse(t("Accounts:closed-account"))}<br/>
                            <i className="cl-black normal text-transform-none">{parse(t("Accounts:closed-on"))} {formatDate(obj.cloturele)}</i></span>
                        </div>
                    }
                </NavLink>
            </SwiperSlide>
            : '')
        })}
        </Swiper>
        </div>
    </div>
    )
}

export default DisplayAccounts