//-> Barre supérieure version desktop (langues / déconnexion / infos utilisateur)

import { NavLink } from "react-router-dom";
import { toggleDropDown, askNewPassword, clearLocalStorage } from "../../components/Helpers";
import { useTranslation } from "react-i18next";

const AccountShortInfoDesktop = (props) => {
    const { t } = useTranslation();
    const { data, history } = props;

    return (
        <>
        {data !== null && 
   
            <ul className='dropdown-container user-menu-desktop box-shadow absolute'>
            {data !== null &&
                <li className="current" onClick={(e) => {
                    toggleDropDown(".user-menu-desktop")
                }}>{data.nomprenom}
                    <ul className="dropdown">
                        <li>
                            <NavLink to="/password-reset" onClick={(e) => {
                                e.preventDefault()
                                askNewPassword()
                                toggleDropDown(".user-menu")
                            }}>{t("Accounts:password-change")}</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={(e)=> {
                                e.preventDefault()
                                clearLocalStorage()
                                history.push({
                                    pathname:"/login"
                                })
                            }} to="/login">{t("Accounts:logout")}</NavLink>
                        </li>
                   
                    </ul>
                </li>
            }
                
            </ul>
        
        }

        </>
    )
}

export default AccountShortInfoDesktop